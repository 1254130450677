<template lang="pug">
  include ../mixins
  buy-action(
    :product-id="objectItem.productId"
    :object-item="{linkOrder: objectItem.orderLink, linkCatalog: objectItem.linkCatalog}"
  )
    template(#default="{ handle }")
      +b.pay-parts
        +e.heading
          +e.preview-wrapper
            +e.IMG.preview(src="/static/img/pay-parts-privatbank.jpg")
          +e.title
            +b.P.text.--color_black.--size_2sm.--height_sm {{_('Оплата частями от ПриватБанк')}}
        +e.content
          row(align="center" justify="end")
            cell(cols="12 narrow-xl")
              +e.payment(v-if="payment")
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm {{ payCount }} &nbsp;
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm(
                  v-word-case:payment="payCount"
                  :key="payCount"
                )
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm &nbsp; {{_('на')}} &nbsp;
            cell(cols="12 7-xl")
              +b.form
                d-control-multiselect.order-multiselect(
                  v-model="payment"
                  track-by="id"
                  label="title"
                  name="payment"
                  label-class="form__label"
                  :options="monthsArray"
                  :placeholder="_('Кількість місяців')"
                  :show-labels="false"
                  :allow-empty="true"
                  :without-label="true"
                  @input="calculatePartPayments"
                )
        +e.total
          +b.P.text.--color_black.--size_3sm.--height_sm.--variant_bold(
            v-if="payment"
          ) {{ partPayment }} {{_('₴ / місяць')}}
        +e.button
          bem-button(
            variant="accent-1"
            width="full"
            space="sm"
            :disabled="payCount === null"
            @event="handleSelectedPayment(handle)"
          ) {{_('Обрати')}}
</template>

<script>
import { handleCase } from '@directives/case/wordCase'

export default {
  props: {
    objectItem: Object,
  },

  data() {
    return {
      payment: '',
      payCount: null,
      partPayment: null,
    }
  },

  computed: {
    monthsArray() {
      let { maxPayCount } = this.objectItem

      const result = []

      --maxPayCount

      for (let i = 1; i <= maxPayCount; ++i) {
        result.push({ title: `${i} ${handleCase(i, 'month', Number(String(i).split('')[String(i).split('').length - 1]))}`, id: i })
      }

      return result
    },
  },

  methods: {
    /* eslint-disable no-undef */
    calculatePartPayments() {
      if (this.payment) {
        const resCalc = PP_CALCULATOR.calculatePhys(this.payment.id, this.objectItem.price)

        this.payCount = resCalc.payCount
        this.partPayment = resCalc.ppValue
      } else {
        this.payCount = null
        this.partPayment = null
      }
    },

    async handleSelectedPayment(buyMethod) {
      if (true === this.objectItem.noRedirect) {
        this.objectItem.promise({
          partCount: this.payCount,
          partPayment: this.partPayment,
          paymentBank: 'pay_part',
        })

        this.$emit('close')
      } else {
        await buyMethod(false)

        localStorage.setItem('pp', this.payCount)

        window.location.replace(this.objectItem.orderLink)
      }
    },
  },
}
</script>
