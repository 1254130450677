<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.footer-form.position--relative
      d-control-pure-input(
        rules="email|required"
        type="email"
        inputmode="email"
        :placeholder="labels.email"
        v-model="formdata.email"
        class-name="footer-form__input"
        :required="false"
        name="email"
        :without-label="true"
        @keyup.enter="validateBeforeSubmit()"
      )
      +e.button.BUTTON(
        @click="validateBeforeSubmit()"
        :disabled="isLoad"
      )
        +b.ICON-SENT.i-icon.--color_white.--size_md
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { subscriptionResource } from '@services/common.service.js'

export default {
  name: 'subscription-form',
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        email: '',
      },
      labels: {
        email: this._('Введіть свій Email'),
      },
    }
  },
  methods: {
    send() {
      return subscriptionResource.execute({}, this.formdata)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
  },
}
</script>
