<template lang='pug'>
  include ../mixins
  +b.DIV
    +b.modal-close--video.BUTTON(@click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_sm
    +b.video
      +e.frame-wrapper
        +e.frame.IFRAME(
          :src='objectItem.src'
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen height='100%'
        )
  </div>
</template>

<script>
export default {
  name: 'video-modal',
  props: {
    objectItem: {},
  },
}
</script>
