<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Відновлення паролю')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3xs
       d-control-input(
          rules="email_phone|required"
          type="text"
          label-class="form__label"
          :input-label="_('Е-mail або номер телефону')"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :required="true"
          name="emailPhone"
          autofocus
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Відновити')}}
</template>

<script>
import {
  reestablishResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import ReestablishCode from './ReestablishCode'

export default {
  name: 'reestablish-password',
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        emailPhone: '',
      },
      response: {},
    }
  },
  methods: {
    send(data) {
      return reestablishResource.execute({}, data)
        .then(res => {
          this.response = res.data.item
          this.handleReestablishCode()
        })
    },
    handleReestablishCode() {
      this.$emit('close')
      this.$modal.show(ReestablishCode, {
        objectItem: this.response,
      },
      {
        name: 'reestablish-code',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
  },
}
</script>
