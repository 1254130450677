<template lang="pug">
  include ../mixins
  +b.section--color_accent-1.--space_2sm.cookie-notification#cookie.SECTION(v-if="showNotification")
    +b.container--common
      bem-button.cookie-notification__close(
        type="close"
        block="icon"
        color="white"
        size="sm"
        @event="closeNotification()"
      )
      bem-text(
        tag="p"
        color="white"
        size="3xs"
        height="sm"
      ) {{_('Lorem ipsum dolor sit amet, consectetur adipisicing elit.')}}
</template>

<script>
export default {
  name: 'cookie-panel',
  data() {
    return {
      showNotification: false,
      footer: null,
    }
  },
  watch: {
    showNotification(nval) {
      this.checkDisplay(nval)
    },
  },
  mounted() {
    this.checkCookie('notification')
    this.footer = document.querySelector('footer')
  },
  methods: {
    closeNotification() {
      const seconds = 60
      const minutes = 60
      const hours = 24
      const days = 365
      const expireDate = seconds * minutes * hours * days
      document.cookie = `notification=true; max-age=${expireDate}; path=/`
      this.showNotification = false
      this.footer.style.paddingBottom = '0px'
    },
    checkCookie(name) {
      const matches = document.cookie.match(new RegExp(
        `(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`
      ))
      if (null === matches) {
        this.showNotification = true
      } else {
        this.showNotification = Boolean(!decodeURIComponent(matches[1]))
        this.checkDisplay(Boolean(!decodeURIComponent(matches[1])))
      }
    },
    checkDisplay(val) {
      setTimeout(() => {
        if (true === val) {
          const notification = document.getElementById('cookie')
          const notificationHeight = notification.clientHeight
          this.footer.style.paddingBottom = `${notificationHeight}px`
        }
      }, 1000)
    },
  },
}
</script>
