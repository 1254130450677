<template lang="pug">
  include ../mixins
  div.position--relative
    component(
      :is-bound="isBound",
      :is='labelComponent',
      :id='id',
      :img="img"
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-required='required'
      :label-class="labelClass"
      v-if="!withoutLabel"
    )
    row(align="center" space="none")
      cell(cols="auto")
        component(
          v-if='mask'
          v-mask="{mask: mask, placeholder: '_', clearMaskOnLostFocus: true, showMaskOnHover: false}"
          :is="element",
          :value.prop='value',
          :checked.prop='value'
          ref='input'
          v-on="listeners"
          :autocomplete="autocomplete"
          v-bind='$attrs'
          :type='type'
          v-model='model',
          :class="className"
          :id="id"
          @focus="isBound = true"
          @blur="isBound = false"
        )
        component(
          v-else
          :is="element",
          :value.prop='value',
          :checked.prop='value'
          ref='input'
          v-on="listeners"
          :autocomplete="autocomplete"
          v-bind='$attrs'
          :type='type'
          v-model='model',
          :class="className"
          :id="id"
          @focus="isBound = true"
          @blur="isBound = false"
        )
          template(
            v-for="(name, key) in eSlots"
            v-slot:[name]="binded"
          )
            slot(
              v-bind="binded"
              :name="key"
            )
        +b.inner-control.--variant_1(
          v-if='type == "password"'
          @click.prevent='changeType()',
        )
          +b.ICON-EYE-ON.i-icon.--size_md.--color_secondary(v-if="show")
          +b.ICON-EYE-OFF.i-icon.--size_md.--color_secondary(v-else)
        +b.inner-control--centered(
          v-if='type == "search"'
        )
          +b.ICON-SEARCH.i-icon.--size_3sm.--color_secondary
        +b.inner-control--close(
          v-if='type == "search" && value && value.length > 0'
          @click="$emit('clear')"
        )
          +b.ICON-CLOSE.i-icon.--size_3sm.--color_secondary
      +b.inner-control--icon.COMPONENT(
        v-if="actionType && actionType.length"
        :is='actionComponent'
        :type="actionType"
        @action="$emit('action', $event)"
      )
</template>

<script>
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'labelComponent',
    'actionComponent',
    'id',
    'img',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'className',
    'labelClass',
    'withoutLabel',
    'actionType',
    'autocomplete',
    'mask',
  ],
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
    },
  },
  data() {
    return {
      input: '',
      show: false,
      isBound: false,
    }
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },

    listeners() {
      return {
        ...this.$listeners,
        input: $event => {
          this.$emit('input', this.getEvent($event))
        },
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  mounted() {
    this.input = this.value
  },
  methods: {
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
    getEvent(event) {
      if (event && event.type) {
        return event.target[this.getKey()]
      }
      return event
    },
  },
}
</script>
