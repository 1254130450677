import Multiselect from 'vue-multiselect'
import { createHOCc } from 'vue-hoc'

export const multiselectDefaults = createHOCc({
  props: [
    'placeholder',
    'tagPlaceholder',
    'showLabels',
    'searchable',
    'multiple',
    'closeOnSelect',
    'hideSelected',
    'taggable',
    'label',
    'preselectFirst',
    'disabled',
  ],
}, {
  props: {
    placeholder() {
      return this.placeholder || this._('Select an option')
    },
    tagPlaceholder() {
      return this.tagPlaceholder || ''
    },
    showLabels() {
      return this.showLabels || false
    },
    searchable() {
      return this.searchable || false
    },
    multiple() {
      return this.multiple || false
    },
    disabled() {
      return this.disabled || false
    },
    closeOnSelect() {
      return this.closeOnSelect || true
    },
    hideSelected() {
      return this.hideSelected || false
    },
    taggable() {
      return this.taggable || false
    },
    label() {
      return this.label || 'title'
    },
    preselectFirst() {
      return this.preselectFirst || false
    },
  },
})

export default multiselectDefaults(Multiselect)
