import './public-path'
import './install-helpers'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Router from 'vue-router'
import Store from 'vuex'
import renderer from '@app/renderer'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import '@/js/directives'
import '@/js/validation'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)

const { app } = createApp({
  Vue,
  I18n,
  Store,
  Router,
  renderer,
})

app.$mount('#app')

extend('email_phone', {
  validate(value) {
    const PHONE_REGEXP = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)|(^\+?(?:[()0-9 -]●?){6,15}[0-9]$)/
    return PHONE_REGEXP.test(value)
  },
  message() {
    return app._('Enter a valid email or phone')
  },
})

extend('international_phone', {
  validate(value) {
    const PHONE_REGEXP = /^\+?(?:[()0-9 -]●?){6,15}[0-9]$/
    return PHONE_REGEXP.test(value)
  },
  message() {
    return app._('Enter a valid phone')
  },
})

extend('url', {
  validate(value) {
    const URL_REGEXP = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    return URL_REGEXP.test(value)
  },
  message() {
    return app._('Enter a valid url address')
  },
})

extend('decimal', {
  validate(value) {
    const DECIMAL_REGEXP = /^([1-9][0-9]{0,9}|0)(\.\d{1,2})?$/
    return DECIMAL_REGEXP.test(value)
  },
  message() {
    return app._('Enter a valid value')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('This field is required.')
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return `${app._('The field must contain at least')} ${sec.length} ${app._('symbols')}`
  },
})

extend('required_if', {
  ...rules.required_if,
  message() {
    return app._('This field is required.')
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Values do not match.')
  },
})

extend('email', {
  ...rules.email,
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('alpha_spaces', {
  ...rules.alpha_spaces,
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('username', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ `'’-]+$/i.test(value)
  },
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('The field must be a number.')
  },
})
