<template lang="pug">
  include ../mixins
  +b.form__absolute-label.LABEL(
    :class="{'is-active': value && value.length }"
    :for='id'
  ) {{ inputLabel }}
    slot
    span.text--color_accent-1(
      v-if='isRequired'
    )  *
</template>

<script>
export default {
  name: 'disappear-label',
  props: {
    labelText: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
