import {
  USER_BASE_PATH,
  USER_ORDERS_PATH,
  USER_SETTINGS_PATH,
  USER_TICKETS_LIST_PATH,
  USER_TICKET_VIEW_PATH,
  USER_FAVORITES_PATH,
  USER_TRACKED_PATH,
} from '@router/router-path'

export const routes = [
  {
    base: USER_BASE_PATH,
    path: USER_BASE_PATH,
    redirect: () => USER_ORDERS_PATH,
    component: () => import('@app/Cabinet/CabinetOrigin'),
    children: [
      {
        path: USER_ORDERS_PATH,
        component: () => import('@app/Cabinet/CabinetOrders'),
        name: 'user-orders',
      },
      {
        path: USER_SETTINGS_PATH,
        component: () => import('@app/Cabinet/CabinetSettings'),
        name: 'user-settings',
      },
      {
        path: USER_TICKETS_LIST_PATH,
        component: () => import('@app/Cabinet/CabinetTicketsList'),
        name: 'user-tickets-list',
      },
      {
        path: USER_TICKET_VIEW_PATH,
        component: () => import('@app/Cabinet/CabinetTicketView'),
        name: 'user-ticket-view',
      },
      {
        path: USER_FAVORITES_PATH,
        component: () => import('@app/Cabinet/CabinetFavorites'),
        name: 'user-favorites',
      },
      {
        path: USER_TRACKED_PATH,
        component: () => import('@app/Cabinet/CabinetTracked'),
        name: 'user-tracked',
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/js/components/Cabinet/NotFound'),
  },
]
