<template lang="pug">
  include ../mixins
  div.position--relative
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--white')
    d-control-multiselect.city-multiselect.ma--bottom_2sm(
      :options="citiesArray"
      v-model="city"
      track-by="label"
      label="title"
      name="sort"
      :allow-empty="true"
      label-class="form__label"
      :input-label="_('Mісто')"
      :placeholder="_('Оберіть місто')"
      @input="filterServices()"
      :searchable="true"
    )
      template(#noResult)
        span {{ _("Елементи не знайдено") }}
      template(#noOptions)
        span {{ _("Список порожній") }}
    +b.service-table.TABLE
      +e.row.TR
        +e.head.TH(v-for="attr in tableHead")
          +b.text--color_black.--size_3xs.--variant_bold.P {{ attr }}
      template(v-for="item in filteredTableData")
        +e.row.TR
          +e.data--gray.TD(colspan="4")
            +b.text--color_black.--size_3xs.--variant_bold.P {{ item.city }}
        +e.row.TR(v-for="service in item.services" :class="{'service-table__row--border': item.services.length > 1}")
          +e.data.TD(:data-label="_('Адреса')")
            +e.content
              +b.text--color_accent-1.--size_3xs.--height_sm.P {{ service.address }}
          +e.data.TD(:data-label="_('Назва центру')")
            +e.content
              +b.text--color_black.--size_3xs.--height_sm.P {{ service.center }}
          +e.data.TD(:data-label="_('Графік роботи')")
            +e.content
              +b.text--color_black.--size_3xs.--height_sm.P {{ service.schedule }}
          +e.data.TD(:data-label="_('Телефон')")
            +e.content
              row(space="sm")
                cell.separated-items(v-for="item in service.phones")
                  +b.link--color_black.--size_3xs.--height_sm.A(:href="'tel:'+item.phone") {{ item.phone }}
</template>

<script>
import {
  serviceCitiesRecource,
  serviceListRecource,
} from '@services/common.service.js'

export default {
  name: 'service-table',
  components: {
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
  },
  data() {
    return {
      citiesArray: [],
      city: '',
      tableHead: [this._('Місто і адреса'), this._('Назва центру'), this._('Графік роботи'), this._('Телефон')],
      tableData: [],
      filteredTableData: [],
      isLoad: false,
    }
  },
  async mounted() {
    this.isLoad = true
    await this.getCities()
    await this.getServices()
    this.filteredTableData = this.tableData
  },
  methods: {
    getCities() {
      return serviceCitiesRecource.execute()
        .then(res => {
          this.citiesArray = res.data.items
        })
    },
    getServices() {
      return serviceListRecource.execute()
        .then(res => {
          this.tableData = res.data.items
          this.isLoad = false
        })
    },
    filterServices() {
      if (null === this.city) this.filteredTableData = this.tableData
      else this.filteredTableData = this.tableData.filter(el => el.label === this.city.label)
    },
  },
}
</script>
