<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
      height="sm"
    ) {{_('Ви активували відновлення пароля')}}
    bem-text.ma--bottom_sm(
      tag="p"
      color="main"
      size="2xs"
      height="sm"
    ) {{_('На Вашу пошту або номер телефону, відправлений перевірочний код, введіть його в поле нижче')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-pure-input(
          rules="required"
          type="text"
          v-model="formdata.secretCode"
          class-name="form__input"
          :required="true"
          name="secretCode"
          :placeholder="_('Введіть перевірочний код *')"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Відновити')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { passwordCodeResource } from '@services/auth.service.js'
import NewPasswordForm from './NewPasswordForm'

export default {
  name: 'reestablish-code',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        secretCode: '',
        userToken: this.objectItem.userToken,
        idUser: this.objectItem.idUser,
      },
    }
  },
  methods: {
    send(data) {
      return passwordCodeResource.execute({}, data)
        .then(res => {
          this.handleNewPasswordModal(res.data.item)
        })
    },
    handleNewPasswordModal(objectItem) {
      this.$emit('close')
      this.$modal.show(NewPasswordForm, {
        objectItem,
      },
      {
        name: 'new-password-form',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
  },

}
</script>
