<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(@click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text(
      :class="{ 'ma ma--bottom_sm': text }"
      appearance="title"
      tag="h3"
      color="accent-1"
      size="md"
      :variant="['center', 'uppercase', 'bold']"
      height="sm"
    ) {{title}}
    bem-text(
      tag="p"
      color="main"
      height="sm"
      size="sm"
      variant="center"
    ) {{text}}
</template>

<script>
export default {
  name: 'success-modal',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>
