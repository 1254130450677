export function djangoToJsBoolean(variable) {
  let value
  if ('True' === variable) value = true
  else if ('False' === variable) value = false
  else value = undefined
  return value
}

export function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function hasLength(obj) {
  return 0 < Object.keys(obj).length
}

export function isObjInArray(arr, key, value) {
  const res = arr.find(el => el[key] === value)
  return res !== undefined
}

export function valueFromSelect(item, key) {
  let res
  if (Array.isArray(item)) {
    res = []
    item.forEach(el => {
      res.push(el[key])
    })
  } else {
    res = item[key]
  }
  return res
}

export function restoreObject(arr, key, value) {
  let item
  if (Array.isArray(value)) {
    item = []
    value.forEach(el => {
      item.push(arr.filter(itemEl => itemEl[key] === el))
    })
  } else {
    item = arr.filter(itemEl => itemEl[key] === value)
  }
  return item
}

export function removeEmptyKeys(obj) {
  Object.keys(obj).forEach(key => {
    if ('' === obj[key] || 0 === obj[key]) delete obj[key]
  })
  return obj
}

export function simplePluralize(n, textForms) {
  /* eslint-disable */
  const n1 = Number(n)
  const n2 = Number(n1) % 10
  if (n1 > 10 && n1 < 20) return textForms[2]
  if (n2 > 1 && n2 < 5) return textForms[1]
  if (n2 == 1) return textForms[0]
  return textForms[2]
}
