<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_3sm(
      tag="h2"
      size="2md"
      height="sm"
      color="black"
    ) {{_('Повідомити коли буде в наявності')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3sm
        d-control-input-disappear(
          rules="required|username"
          type="text"
          :input-label="_('Ваше ім`я')"
          v-model="formdata.name"
          class-name="form__input"
          :required="true"
          name="name"
        )
      +b.form.position--relative.ma--bottom_sm
        d-control-input-disappear(
          rules="email_phone|required"
          type="text"
          :input-label="_('Email або телефон')"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :required="true"
          name="emailPhone"
        )
      validation-provider(
        slim
        v-slot="{errors}"
        name="product"
      )
        +b.P.error-text.ma--bottom_sm(v-if="errors[0]") {{ errors[0] }}
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Відправити')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { trackAvailabilityResource } from '@services/common.service.js'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'availability-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        name: '',
        emailPhone: '',
        product: '',
      },
      isAuth: djangoToJsBoolean(window.is_auth),
      getParams: {},
    }
  },
  watch: {
    user: {
      handler(nval) {
        if (true === this.isAuth) {
          Object.keys(this.formdata).forEach(key => {
            this.formdata[key] = nval[key]
          })
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
          this.formdata.emailPhone = nval.email || nval.phone
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (true === this.isAuth) {
      this.userDataRequest()
    }
    this.parseParameters()
  },
  methods: {
    send(data) {
      this.formdata.product = this.objectItem.productId
      data.getParams = this.getParams
      return trackAvailabilityResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res }, this.reload)
        })
    },
    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')
      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },
    reload() {
      window.location.reload()
    },
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
