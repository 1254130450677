<template lang="pug">
  include ../mixins
  div
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--white')
    +b.modal-close--common.BUTTON(
      @click="$emit('close')"
    )
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_3sm(
      v-if="cartLength && isResponse"
      tag="h2"
      size="2md"
      color="black"
      height="sm"
    ) {{_('Ви додали до кошика')}}&nbsp;
      bem-text.ma--bottom_3sm(
        tag="span"
        size="2md"
        color="accent-1"
      ) {{ counter }} {{ pluralizedProductWord }}
    bem-text.ma--bottom_3sm(
      v-else
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Кошик')}}
    template(v-if="isResponse && cartLength")
      +b.cart__wrapper
        cart-item.card--color_light-2.ma--bottom_3xs(
          v-for="product in cartData.products"
          :key="product.id"
          :product-data="product"
          @update-data="updateCart($event)"
          @delete-item="addGtagRemoveFromCartEvent"
          @is-counter-error="orderDisable = $event"
        )
      +b.ROW.ma.--top_sm.--top_md-sm.--bottom_sm.--bottom_md-sm(justify="end-md")
        cell(cols="12")
          row(justify="center")
            cell(cols="auto")
              bem-text.ma--bottom_sm(
                v-if="cartData.discount"
                tag="p"
                color="black"
                size="2sm"
                variant="right"
              ) {{_('Загальна вартість:')}}
              bem-text.ma--bottom_sm(
                v-if="cartData.discount"
                tag="p"
                color="accent-2"
                size="2xs"
                variant="right"
              ) {{_('Знижка:')}}
              bem-text(
                tag="p"
                color="black"
                :variant="['bold', 'right']"
                size="2sm"
              ) {{_('Сума до сплати:')}}
            cell(cols="narrow")
              bem-text.ma--bottom_sm(
                v-if="cartData.discount"
                tag="p"
                color="black"
                size="2sm"
              ) {{ cartData.full }} {{_('грн')}}
              bem-text.ma--bottom_sm(
                v-if="cartData.discount"
                tag="p"
                color="accent-2"
                size="2xs"
              ) {{ cartData.discount }} {{_('грн')}}
              bem-text(
                tag="p"
                color="black"
                variant="bold"
                size="2sm"
              ) {{ cartData.finalPrice }} {{_('грн')}}
    +b.ma.--top_sm.--bottom_sm.display_none-lg(v-if="isResponse && !showOneClickForm")
      +b.g-row.--align_center
        +b.g-cell
          icon-chevron-left
        +b.g-cell
          +b.SPAN.link.--color_accent-1.--size_sm(
            @click.prevent="$emit('close')"
          ) {{_('Продовжити покупки')}}
    +b.card--color_light-2.--space_lg(v-if="isResponse && !cartLength")
      row(justify="center" align="center" space="md-md")
        cell(cols="narrow")
          +b.cart__img--modal.IMG(src="/static/img/empty-cart.png")
        cell(cols="auto 4-md 8-lg 4-xl")
          bem-text(
            tag="p"
            color="black"
            height="sm"
          ) {{_('Ваш кошик порожный, саме час робити купування')}}
    +b.cart__border.ma.--bottom_sm.--bottom_md-lg
    template(v-if="showOneClickForm")
      buy-one-click-form(
        :cart-data="cartData"
      )
    template(v-else)
      +b.g-row.--align_center.--appearance_spaced(
        v-if="isResponse"
        :class="cartLength && isResponse ? 'g-row--justify_between' : 'g-row--justify_center'"
      )
        +b.g-cell.g-cols--12.--narrow-lg.display_none.display_block-lg
          +b.g-row.--align_center
            +b.g-cell
              icon-chevron-left
            +b.g-cell
              +b.SPAN.link.--color_accent-1.--size_sm(
                @click.prevent="$emit('close')"
              ) {{_('Продовжити покупки')}}
        cell(
          v-if="cartLength"
          cols="12 auto-lg"
        )
          +b.g-row.--align_center.--appearance_spaced.--justify_end.--direction_column-reverse-till-sm
            cell(
              cols="12 6-sm 5-lg"
            )
              +b.SPAN.button--variant_sm.--space_sm.--variant_accent-1.--variant_accent-3.--width_full(
                @click.prevent="openFormBuyOneClick"
              ) {{_('Купити в 1 клік')}}
            cell(
              cols="12 6-sm 5-lg"
            )
              bem-link.button--variant_sm(
                block="button"
                variant="accent-1"
                width="full"
                space="sm"
                :href="objectItem.linkOrder"
                :class="{'is-disabled': orderDisable || !cartData.finalPrice }"
              ) {{_('Оформити замовлення')}}
        cell(
          v-else
          cols="12 6-sm 4-lg"
        )
          bem-link(
            block="button"
            variant="accent-1"
            width="full"
            space="sm"
            :href="indexPageUrl"
          ) {{_('На головну')}}
</template>

<script>
import {
  cartChangeResource,
  cartListResource,
} from '@services/order.service.js'
import { cartData } from '@mixins/order.mixin.js'
import { hasLength, simplePluralize } from '@helpers/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'cart-modal',
  components: {
    'cart-item': () => import('./CartItem'),
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    'buy-one-click-form': () => import('@app/Cart/BuyOneClickForm'),
  },
  props: {
    objectItem: Object,
  },
  data() {
    return {
      isResponse: false,
      cartLength: false,
      cartData: {},
      isLoad: false,
      orderDisable: false,
      counter: 0,
      indexPageUrl: window.indexPageUrl,
      showOneClickForm: false,
    }
  },
  async mounted() {
    await this.getCart()

    this.cartCountRequest('cart')
  },
  computed: {
    ...mapGetters('common', {
      cart: 'getCartData',
    }),
    pluralizedProductWord() {
      const variants = [
        this._('товар'),
        this._('товари'),
        this._('товарів'),
      ]
      return simplePluralize(this.cart.quantity, variants)
    },
  },
  watch: {
    cart: {
      handler(nval) {
        this.counter = nval.quantity
      },
    },
  },
  methods: {
    ...mapActions('common', [
      'cartCountRequest',
    ]),
    getCart() {
      this.isLoad = true
      return cartListResource.execute()
        .then(res => {
          if ((0 < res.data.item?.products?.length) || (0 < res.data.item?.products?.length)) {
            this.cartData = res.data.item
            this.cartLength = hasLength(this.cartData)
          } else {
            this.cartData = {}
            this.cartLength = false
          }
          this.isLoad = false
          this.isResponse = true
        })
    },

    addGtagRemoveFromCartEvent({ product, count }) {
      const { code, title, prices, color, category } = product
      const { price2 } = prices
      const products = [{
        id: code,
        name: title,
        category,
        price: price2,
        quantity: count,
        variant: color || '',
      }]

      const finalPrice = products.reduce((acc, el) => {
        acc += el.price * el.quantity
        return acc
      }, 0)

      /* eslint-disable-next-line */
      gtag('event', 'remove_from_cart', {
        items: products,
        currency: 'UAH',
        value: Number(finalPrice),
      })
    },

    updateCart(data) {
      this.isLoad = true
      const formdata = cartData([data.id], data.count)
      return cartChangeResource.execute({}, formdata).then(res => {
        if ((0 < res.data.item?.products.length) || (0 < res.data.item.products.length)) {
          this.cartData = res.data.item
          this.cartLength = hasLength(this.cartData)
        } else {
          this.cartData = {}
          this.cartLength = false
        }
        this.cartCountRequest('cart')
        this.isLoad = false
      })
    },

    openFormBuyOneClick() {
      this.showOneClickForm = true
    },
  },
}
</script>
