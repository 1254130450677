import { getCookie } from '@resource/cookie'

export const getAmplitudeInfo = () => {
  const google_client_id_dirty = getCookie('_ga') || ''
  const google_client_id = google_client_id_dirty.substring(6)
  const clarity_id = getCookie('_clck') || ''
  const user_id = window.user_id || ''

  return {
    google_client_id,
    clarity_id,
    user_id,
  }
}
