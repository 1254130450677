<template lang="pug">
include ../mixins
+b.c-card
  ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--white')
  +b.c-panel.--space_bottom-2
    +b.P.c-caption.--size_2 {{ _("Оберіть налаштування каталогу для завантаження файлу") }}
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols--12.--narrow-md
      export-content-file-format(
        :items="choices.fileFormat"
        :formdata="formdata"
      )
    +b.g-cell.g-cols--12.--narrow-md.c-divider.--variant_1
    +b.g-cell.g-cols--12.--narrow-md
      export-content-language(
        :items="choices.fileLanguage"
        :formdata="formdata"
      )
  +b.c-divider
  export-content-categories(
    :items="choices.category"
    :formdata="formdata"
  )
  +b.c-divider
  export-content-additional(
    :items="choices.additional"
    :formdata="formdata"
  )
  +b.c-divider
  +b.g-row.--justify_center.--justify_end-sm
    +b.g-cell.g-cols
      +b.BUTTON.button.--variant_accent-1.--variant_5.--width_1(
        @click.prevent="validateBeforeSubmit"
        :disabled="isLoad"
      ) {{ _("Завантажити") }}
</template>

<script>
import {
  contentPayloadsResource,
  contentCategoriesResource,
  contentExportCatalogResource,
} from '@services/content.service.js'
import { openMessageModal } from '@helpers/submit'
import exportFile from '@helpers/exportFile'

export default {
  components: {
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    'export-content-additional': () => import('@app/ExportContent/components/Additional'),
    'export-content-file-format': () => import('@app/ExportContent/components/FileFormat'),
    'export-content-language': () => import('@app/ExportContent/components/Language'),
    'export-content-categories': () => import('@app/ExportContent/components/Categories'),
  },

  data() {
    return {
      isLoad: false,
      formdata: {
        additional: [],
        categories: [],
        fileLanguage: null,
        fileFormat: null,
      },
      choices: {
        fileFormat: [],
        fileLanguage: [],
        additional: [],
        category: [],
      },
    }
  },

  async created() {
    await this.getPayloads()
    await this.getCategories()

    this.preselectData()
  },

  methods: {
    async getPayloads() {
      const { data: { item } } = await contentPayloadsResource.execute()

      this.choices.fileFormat = item.fileFormat.choices
      this.choices.fileLanguage = item.fileLanguage.choices
      this.choices.additional = item.additional.choices
    },

    async getCategories() {
      const { data: { items } } = await contentCategoriesResource.execute()

      const filteredItems = items.filter(el => el.children.length)

      this.choices.category = filteredItems
    },

    preselectData() {
      this.formdata.fileFormat = this.choices.fileFormat[0].value
      this.formdata.fileLanguage = this.choices.fileLanguage[0].value

      this.choices.additional.forEach(item => {
        this.formdata.additional.push(item.value)
      })
    },

    validateBeforeSubmit() {
      const parentIds = this.choices.category.map(el => el.id)
      const categories = this.formdata.categories.filter(categoryId => !parentIds.includes(categoryId))
      const hasCategories = categories.length
      const hasAdditional = this.formdata.additional.length

      if (!hasCategories) {
        const title = this._('Оберіть хоча б одну категорію другого рівня')

        openMessageModal({ title }, this)

        return
      }

      if (!hasAdditional) {
        const title = this._('Оберіть хоча б одну додаткову опцію')

        openMessageModal({ title }, this)

        return
      }

      this.send()
    },

    prepareFormdata() {
      const formdata = JSON.parse(JSON.stringify(this.formdata))

      const parentIds = this.choices.category.map(el => el.id)

      formdata.categories = formdata.categories.filter(categoryId => !parentIds.includes(categoryId))

      return formdata
    },

    send() {
      if (this.isLoad) {
        return
      }

      const formdata = this.prepareFormdata()

      this.isLoad = true

      exportFile(contentExportCatalogResource, formdata).then(() => {
      }).finally(() => {
        this.isLoad = false
      })
    },
  },
}
</script>
