<script>
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'track-action',
  props: {
    productId: Number,
    isActive: [String, Boolean],
  },
  data() {
    return {
      isDisable: djangoToJsBoolean(this.isActive) || this.isActive,
    }
  },
  methods: {
    handleTrackedModal() {
      this.$modal.show(() => import('@app/Forms/AvailabilityForm'), {
        component: this.componentName,
        objectItem: { productId: this.productId },
      }, {
        classes: 'modal modal--size_sm modal--container modal--padding_md modal--border_sm',
        name: 'modal',
      }, {
        'before-close': () => { this.$emit('action') },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      openModal: this.handleTrackedModal,
      isActive: this.isDisable,
    })
  },
}
</script>
