<template lang="pug">
  include ../mixins
  row(space="xs" align="center" appearance='nowrap')
    cell(cols="narrow")
      modal-handler(
        component-name="cart-modal"
        modal-class="modal modal--size_cart modal--variant_cart modal--container modal--padding_sm modal--border_sm"
        :object-item="{ linkOrder, linkCatalog }"
      )
        template(#default="{ handler }")
          +b.action-button
            +e.wrapper
              +e.button.BUTTON(@click="handler" aria-label="cart")
                +b.ICON-CART.i-icon.--color_accent-2.--size_1.--color_main
              +e.counter.remove-hover(v-if="counter > 0") {{ counter }}
    +b.u-display--hidden.--block-xxl
      +b.CELL(v-if="counter > 0")
        +b.action-button__text.text--color_black.--size_2xs.--variant_center.--height_sm.P {{ counter }} &nbsp;
          span(v-word-case:goods="counter" :key="counter")
          span &nbsp;{{_('на сумму')}}
          span &nbsp; {{ totalPrice }}
          span &nbsp; {{_('грн')}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'cart-button',
  props: {
    count: Number,
    total: Number,
    linkOrder: String,
    linkCatalog: String,
  },
  data() {
    return {
      counter: this.count,
      word: '',
      totalPrice: this.total,
      currentLang: window.language,
    }
  },
  watch: {
    cart: {
      handler(nval) {
        this.counter = nval.quantity
        this.totalPrice = nval.finalPrice
      },
    },
  },
  computed: {
    ...mapGetters('common', {
      cart: 'getCartData',
    }),
  },
}
</script>
