import { scrollUnlock, scrollLock } from '@helpers/scroll'

export function mobileMenuToggler() {
  const menu = document.querySelector('.js-menu')
  const togglers = document.querySelectorAll('.js-toggler')
  const close = document.querySelector('.js-close')
  const mask = document.querySelector('.js-mask')

  if (menu) {
    togglers.forEach(toggler => {
      toggler.onclick = () => {
        menu.classList.add('is-active')
        toggler.classList.add('is-active')
        mask.classList.add('is-active')
        scrollLock()
      }
    })
    close.onclick = () => {
      menu.classList.remove('is-active')
      togglers.forEach(toggler => {
        toggler.classList.remove('is-active')
      })
      mask.classList.remove('is-active')
      scrollUnlock()
    }
    mask.onclick = () => {
      menu.classList.remove('is-active')
      mask.classList.remove('is-active')
      scrollUnlock()
    }
  }
}
