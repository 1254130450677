<template lang="pug">
include ../mixins

d-control-multiselect.order-multiselect(
  v-model="model"
  :name="filter.name"
  :track-by="filter.meta.value"
  :multiple="filter.meta.multiple || false"
  :searchable="true"
  :internal-search="false"
  :allow-empty="true"
  :options="result.items"
  :input-label="filter.title"
  :option-label="filterLabel"
  :class="inputClass"
  :placeholder="filter.meta.placeholder || ''"
  @select="debounceUpdate"
  @search-change="debounceSearch"
)
  template(#noResult)
    span {{ _("За Вашим запитом нічого не знайдено") }}
  template(#noOptions)
    span {{ _("Почніть вводити назву") }}
  template(#afterList)
    div(
      v-if="hasNextPage"
      v-observe-visibility="setVisibility"
    )
</template>

<script>
import { debounce } from 'lodash'
import { createResource } from '@resource/resource'

const INITIAL_LIMIT = 20

export default {
  props: {
    value: {},
    initialReceive: Boolean,
    filter: Object,
    config: Object,
    resetTrigger: Boolean,
    emitFullValue: Boolean,
    inputClass: String,
  },

  data() {
    return {
      model: this.value,
      filterLabel: this.filter.meta.title || 'title',
      result: {
        items: [],
        pagination: {
          limit: INITIAL_LIMIT,
          offset: 0,
          total: 0,
        },
      },
      isPagination: false,
      query: '',
      debounceUpdate: () => {},
      debounceSearch: () => {},
    }
  },

  computed: {
    hasNextPage() {
      const { pagination } = this.result

      return pagination.total > pagination.limit + pagination.offset
    },

    resource() {
      const { meta } = this.filter
      const queryParams = ['limit', 'offset']

      if (meta.initialParams) {
        Object.keys(meta.initialParams).forEach(key => {
          queryParams.push(key)
        })
      }

      if (meta.query_param) {
        queryParams.push(meta.query_param)
      }

      const queryString = queryParams.join(',')

      return createResource(`${meta.url}{?${queryString}}`)
    },
  },

  watch: {
    value: {
      handler(nval) {
        this.setValueFromUrl(nval)
      },
    },

    resetTrigger: {
      handler() {
        this.model = null
      },
    },
  },

  async mounted() {
    this.debounceUpdate = debounce(this.update, 100)
    this.debounceSearch = debounce(this.search, 500)

    if (this.initialReceive) {
      await this.receive()

      this.preselectValue()
    }
  },

  methods: {
    update(value) {
      const valuekey = this.filter.meta.value

      if (!value) {
        this.$emit('input', value)

        return
      }

      if (this.filter.multiple) {
        const normalizedTo = value.map(el => el[valuekey])

        this.$emit('input', normalizedTo)
      } else if (this.emitFullValue) {
        this.$emit('input', value)
      } else {
        const normalizedTo = value[valuekey]

        this.$emit('input', normalizedTo)
      }
    },

    normalizeFrom(val) {
      if (Array.isArray(val)) {
        return val
      }
      return [val]
    },

    clearValue() {
      this.model = null
    },

    async setValueFromUrl(val) {
      if (!val) return

      const normalizedValue = this.normalizeFrom(val)
      const valuekey = this.filter.meta.value
      const checked = this.result.items.filter(el => normalizedValue.find(v => (v).toString() === (el[valuekey]).toString()))

      if (checked.length) {
        if (this.filter.multiple) {
          this.model = checked
        } else {
          const [option] = checked

          this.model = option
        }
      } else if (this.hasNextPage) {
        await this.setVisibility(true)

        this.preselectValue()
      }
    },

    async receive() {
      const { pagination } = this.result
      const preparedParams = {
        limit: pagination.limit,
        offset: pagination.offset,
      }

      const { meta } = this.filter

      if (meta && meta.initialParams) {
        Object.keys(meta.initialParams).forEach(key => {
          preparedParams[key] = meta.initialParams[key]
        })
      }

      if (this.query) {
        preparedParams[this.filter.meta.query_param] = this.query
      }

      const { data } = await this.resource.execute(preparedParams)

      if (this.isPagination) {
        this.result.items.push(...data.items)
        this.result.pagination = data.pagination
      } else {
        this.result = data
      }
    },

    async search(val) {
      this.isPagination = false

      this.result.pagination.limit = INITIAL_LIMIT
      this.result.pagination.offset = 0
      this.result.pagination.total = 0

      this.query = val

      this.$emit('search', true)

      await this.receive()

      this.$emit('search', false)
    },

    async setVisibility(isReached) {
      if (isReached) {
        const { limit, offset } = this.result.pagination

        this.result.pagination.offset = offset + limit

        this.isPagination = true

        await this.receive()
      }
    },

    preselectValue() {
      if (this.modelValue) {
        this.setValueFromUrl(this.modelValue)
      }
    },
  },
}
</script>
