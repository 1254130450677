<script>
import { turnFavoriteResource } from '@services/actions.service.js'
import { djangoToJsBoolean } from '@helpers/utils'
import { mapActions } from 'vuex'
import AuthForm from '@app/Forms/AuthForm'
import ActionsMixin from '@mixins/actions.mixin.js'

export default {
  name: 'favorite-action',
  mixins: [ActionsMixin],
  props: {
    productId: {},
    isActive: {},
    objectItem: Object,
  },
  data() {
    return {
      isActiveProduct: false,
      isAuth: djangoToJsBoolean(window.is_auth),
    }
  },
  mounted() {
    if ('boolean' === typeof this.isActive) this.isActiveProduct = this.isActive
    else this.isActiveProduct = djangoToJsBoolean(this.isActive)
  },
  methods: {
    handleClick() {
      if (true === this.isAuth) {
        this.send()
      } else {
        this.handleAuth()
      }
    },
    send() {
      return turnFavoriteResource.execute({}, { id_product: this.productId })
        .then(res => {
          this.isActiveProduct = res.data.item.create
          this.changeAction({ key: 'favorite', res: res.data.item.count })
          this.showNotification(this._('Додано в обране'), this._('Видалено з обраного'))
          // emit to product card template
          this.$emit('turn', this.productId)
        })
    },
    handleAuth() {
      this.$modal.show(AuthForm, {
        objectItem: this.objectItem,
      },
      {
        name: 'auth-form',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
    ...mapActions('common', [
      'changeAction',
    ]),
  },
  render() {
    return this.$scopedSlots.default({
      action: this.handleClick,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
