<template lang="pug">
  include ../mixins
  div
    vue-star-rating(
      :inline="true"
      :star-size="starSize"
      :read-only="readOnly"
      :increment="increment"
      :show-rating="false"
      @rating-selected="setRating"
      :rating="rating"
      active-color="#008CFF"
      inactive-color="#E0E0E0"
      :padding="2"
      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
    )
</template>

<script>
import VueStarRating from 'vue-star-rating'

export default {
  name: 'ui-star-rating',
  components: {
    VueStarRating,
  },
  props: {
    readOnly: {
      required: true,
      type: Boolean,
    },
    currentRating: {
      type: Number,
      required: false,
      default: 0,
    },
    starSize: {
      default: 20,
    },
    increment: {
      default: 1,
    },
  },
  data() {
    return {
      rating: this.currentRating,
    }
  },
  methods: {
    setRating(rating) {
      this.$emit('rating-selected', rating)
    },
  },
}
</script>
