import { prefixAPI, baseAPI } from '@resource/api.js'

export const PRODUCT_CATALOG = {
  CARDS: baseAPI('/{slug}/category-card/{;filters*}/page/{page}'),
  FILTERS: prefixAPI('filter-catalog/{slug}/{;filters*}/page/{page}'),
}

export const AUTH = {
  REGISTRATION: prefixAPI('auth/registration/'),
  ACTIVATION: prefixAPI('auth/activate/'),
  SEND_CODE: prefixAPI('auth/gen-send-code/'),
  CHANGE_PHONE_EMAIL: prefixAPI('auth/change-email-phone/'),
  SIGNIN: prefixAPI('auth/login/'),
  LOGOUT: prefixAPI('auth/logout/'),
  CHANGE_PASSWORD: prefixAPI('auth/password/change/'),
  SET_NEW_PASSWORD: prefixAPI('auth/password/reset/confirm/'),
  PASSWORD_CODE: prefixAPI('auth/password/reset/check-code/'),
  REESTABLISH_PASSWORD: prefixAPI('auth/password/reset/request/'),
  CHANGE_EMAIL: prefixAPI('personal/change/check_email/'),
  CHANGE_EMAIL_CODE: prefixAPI('personal/change/set_email/'),
  CHANGE_PHONE: prefixAPI('personal/change/check_phone/'),
  CHANGE_PHONE_CODE: prefixAPI('personal/change/set_phone/'),
  SOCIAL_EMAIL_PHONE_SET: prefixAPI('auth/socila-set-email/'),
}

export const REVIEW = {
  ADD: prefixAPI('review-add/'),
  CARDS: baseAPI('/product/{slug}/reviews-card/page/{page}/'),
}

export const COMMON = {
  TRACK_AVALIABILITY: prefixAPI('tracker-add/'),
  DELETE_TRACK: prefixAPI('tracker-del/'),
  FIND_CHEAPER: prefixAPI('cheaper-add/'),
  FEED_BACK: prefixAPI('feed-back/add/'),
  SERVICE_CITIES: prefixAPI('services/list-cities/'),
  SERVICE_LIST: prefixAPI('services/list/'),
  HEADER_SEARCH: prefixAPI('product/search-header/'),
  SEARCH_FILTERS: prefixAPI('product/filter-search/{;search*}/'),
  SEARCH_RESULT_CARDS: baseAPI('/search-result-ajax/{;filters*}/page/{page}/'),
  SUBSCRIPTION_PATH: prefixAPI('subsribe/create/'),
}

export const ACTIONS = {
  FAVORITE: prefixAPI('like/turn/'),
  CART_COUNT: prefixAPI('cart/quantity/'),
}

export const COMPARISON = {
  TURN: prefixAPI('comparison/turn/'),
  DELETE_CATEGORY: prefixAPI('comparison/drop-category/'),
  GET_CATEGORY_COMPARE: prefixAPI('comparison/category-detail/'),
}

export const ORDER = {
  CART: prefixAPI('cart/change/'),
  LIST: prefixAPI('cart/retrieve/'),
  CITIES_LIST: prefixAPI('new-post/cities-list/'),
  DEPARTMENTS_LIST: prefixAPI('new-post/filials-list/'),
  STREETS_LIST: prefixAPI('new-post/streets-search/'),
  WAREHOUSES_LIST: prefixAPI('warehouse/list/'),
  ORDER_CREATE: prefixAPI('order/create/'),
  ORDER_CREATE_ONE_CLICK: prefixAPI('order/one-click/'),
  PROMOCODE: prefixAPI('cart/price-promocode/'),
}

export const CABINET = {
  USER: prefixAPI('personal/detail/'),
  USER_UPDATE: prefixAPI('personal/update/'),
  FAVORITE_LIST: baseAPI('/favorite/page/{page}'),
  TRACKED_LIST: baseAPI('/tracked/page/{page}'),
  TICKETS_LIST: prefixAPI('personal/ticket/list/'),
  TICKET_CREATE: prefixAPI('personal/ticket/create/'),
  TICKET_MESSAGE_CREATE: prefixAPI('personal/ticket/create-message/'),
  TICKET_DETAIL: prefixAPI('personal/ticket/detail/'),
  TICKET_CLOSE: prefixAPI('personal/ticket/close/'),
  ORDERS_LIST: prefixAPI('order/list/'),
  ORDERS_CANCEL: prefixAPI('order/set-client-refusal/{id}/'),
  ORDERS_PAYMENT_METHODS_PAYLOAD: prefixAPI('order/change-pay-method-payloads/{pk}/'),
  ORDERS_CHANGE_PAYMENT_METHOD: prefixAPI('order/change-pay-method/{pk}/'),
}

export const CONFIGURATOR = {
  CONFIGURATOR_ADD: prefixAPI('constructor/turn-product/'),
  CONFIGURATOR_DETAIL: prefixAPI('constructor/detail/'),
  CONFIGURATOR_SELECT: prefixAPI('constructor/select-product/'),
  CONFIGURATOR_DELETE_GROUP: prefixAPI('constructor/del-group/'),
}

export const PAGINATION = {
  ARTICLE_PAGINATION: baseAPI('/news/article-ajax/page/{page}/'),
  VIDEO_PAGINATION: baseAPI('/news/video-ajax/page/{page}/'),
  SET_PAGINATION: baseAPI('/set/list-ajax/page/{page}/'),
}

export const CONTENT = {
  CATEGORIES: prefixAPI('content/categories/'),
  PAYLOADS: prefixAPI('content/payloads/'),
  PRODUCT_AUTOCOMPLETE: prefixAPI('content/search-product'),
  EXPORT_CATALOG: prefixAPI('content/export/catalog/'),
  EXPORT_PRODUCTS: prefixAPI('content/export/products/'),
}

export const PAYPARTS = {
  MONOBANK_INFO: prefixAPI('payparts/monobank/'),
}
