<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      @click="$emit('close')"
    )
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Змінити email')}}
    validation-observer.position--relative(
      tag="form"
      ref="observer"
      autocomplete="off"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3xs
        d-control-input(
          autocomplete="off"
          rules="email|required"
          inputmode="email"
          type="email"
          label-class="form__label"
          :input-label="_('Email')"
          v-model="formdata.newEmail"
          class-name="form__input"
          :required="true"
          name="newEmail"
          :disabled="canEdit === false"
          autofocus
        )
      +b.form
        d-control-input(
          autocomplete="new-password"
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Пароль')"
          :required="true"
          name="password"
          :disabled="canEdit === false"
        )
      row.ma--bottom_sm(justify="end" v-if="isCode === true && canEdit === false")
        cell
          +b.link--color_accent-1.--size_2xs(@click="accessEditing") {{_('Я помилився, ввести знову')}}
          //- bem-link(
          //-   tag="div"
          //-   block="link"
          //-   color="accent-1"
          //-   size="2xs"

          //- )
      code-form(
        ref="codeEmail"
        v-if="isCode === true && canEdit === false"
        :formdata="codeData.secretCode"
        :message="message"
        @update="codeData.secretCode = $event"
      )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.ma--top_sm(
        v-if="isCode === false && canEdit === true"
        variant="accent-1"
        width="full"
        :disabled="isLoad"
        space="sm"
        @event="validateBeforeSubmit()"
      ) {{_('Змінити')}}
      bem-button.ma--top_sm(
        v-else
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit(sendCode)"
      ) {{_('Підтвердити')}}
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { changeEmailResource, setEmailCodeResource } from '@services/auth.service.js'

export default {
  name: 'change-email-form',
  mixins: [FormMixin],
  components: {
    'code-form': () => import('@app/Forms/CodeForm'),
  },
  data() {
    return {
      formdata: {
        newEmail: '',
        password: '',
      },
      codeData: {
        secretCode: '',
      },
      isCode: false,
      canEdit: true,
      message: this._('На Вашу пошту відправлений перевірочний код, введіть його в поле нижче'),
    }
  },
  methods: {
    send(data) {
      this.fieldErrors = []
      return changeEmailResource.execute({}, data)
        .then(() => {
          this.isCode = true
          this.canEdit = false
          this.scrollIntoView('codeEmail')
        })
    },
    sendCode() {
      this.fieldErrors = []
      this.codeData.email = this.formdata.email
      return setEmailCodeResource.execute({}, this.codeData)
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.userDataRequest()
        })
    },
    // updateValidator(errors) {
    //   this.fieldErrors.push(errors.nonFieldErrors.message)
    // },
    accessEditing() {
      this.canEdit = true
      this.isCode = false
      this.codeData.secretCode = ''
    },
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
    scrollIntoView(id) {
      setTimeout(() => {
        const item = document.getElementById(id)
        item.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }, 1000)
    },
  },
}
</script>
