<template lang="pug">
  include ../mixins
  div
    +b.container--common
      +b.section--space_top-banner.--img.--height_top-md.ma--bottom_2sm.SECTION
        picture
          source(
            type="image/webp"
            :srcset="bannerImgWebp"
          )
          +e.image.IMG(
            :src="bannerImg"
          )
        +b.g-row
          +b.g-cell.g-cols--9-xl
            +b.banner-title.text--color_white.H1 {{_('Знайдено')}} &nbsp;
              +b.banner-title.text--color_white.SPAN {{ amount }} &nbsp;
              span &nbsp;
              +b.banner-title.text--color_white.SPAN(v-word-case:goods="amount" :key="amount")
    +b.section--color_light.--space_2sm.SECTION
      +b.container--common
        template(v-if="pagination.count === 0 && isResponse === false")
          bem-text.ma--center.display_block(
            tag="p"
            color="main"
            height="sm"
            variant="center"
          ) {{_('За вашим запитом не знайдено жодних результатів')}}
        row(space="sm-xl").position--relative(v-else)
          ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
          cell(cols="12 3-xl")
            div(v-show="filters.length !== 0")
              +b.filter-btn.ma--center.ma--bottom_sm.u-display--hidden-xl.BUTTON(
                ref="filterButton"
                @click="toggleFilter"
              )
                +e.SPAN.text {{_('Фільтри')}}
                +e.SPAN.icon
                  icon-filter
              +b.card--color_white.--variant_fullheight.ma--bottom_3sm.filter-wrapper.position--relative(
                :class="{'is-active': isShowFilters == true}"
              )
                +b.filter-close.u-display--hidden-xl.BUTTON(@click="toggleFilter")
                  +b.ICON-CLOSE.i-icon.--size_3sm.--color_main
                +b.card--color_white.--space_2xs.--space_2sm-xl
                  filter-section(
                    v-if="showFilters"
                    :filters="filters"
                    :params-obj="paramsObj"
                    :is-selected-block="isSelectedArray"
                    @update-params="paramsObj = $event; isAddPage = false; getCards()"
                    @clear-filters="clearFilters"
                  )
                  +b.filter-wrapper__button.card--color_white.--space_2xs.u-display--hidden-xl.filter-apply(
                    :class="{'is-active': isShowFilters == true}")
                    row(justify="center")
                      cell(cols="12 8-sm 4-md")
                        bem-button(
                          variant="accent-1"
                          width="full"
                          space="xs"
                          @event="toggleFilter()"
                        ) {{_('Застосувати')}}
          cell(cols="12 9-xl")
            +b.g-row.--space_2xs.--space_sm-xl.--appearance_spaced(
              v-if="!isResponse"
              ref="card"
            )
              slot(name="cards")
            //- renderer.ma--bottom_2md(
            //-   v-if="isResponse"
            //-   :result="response"
            //-   class-render='g-row g-row--space_sm-xxl'
            //- )
            +b.g-row.--space_2xs.--space_sm-xl.--appearance_spaced(v-if="isResponse")
              cell(cols="6 4-lg" v-for="card in response")
                //-JSON response
                product-card(
                  :card-data="card"
                  :auth-object-item="authObjectItem"
                  :cart-object-item="cartObjectItem"
                )
            bem-text(
              v-if="isResponse && response.length === 0"
              tag="p"
              color="main"
              size="sm"
              variant="center"
            ) {{_('За вашим запитом не знайдено жодних результатів')}}
            row.ma--top_md(justify="center")
              cell(cols="12 9-lg 6-xxl")
                pagination(
                  v-if="count > perPage"
                  class-wrapper="pagination__wrapper--light"
                  :is-scroll="false"
                  :is-scroll-buttons="true"
                  :current='page'
                  :total='count'
                  :perPage='perPage'
                  :is-add-more="true"
                  @add="isAddPage = $event"
                  @page-changed="page = $event, getCards($event)"
                )
</template>

<script>
import CatalogMixin from '@mixins/catalog.mixin.js'

import {
  searchFiltersResource,
  searchCardsResource,
} from '@services/common.service.js'

const one = 1

export default {
  name: 'search-catalog',
  components: {
    'filter-section': () => import('@app/Catalog/FilterSection'),
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    'product-card': () => import('@app/Cards/ProductCard'),
    pagination: () => import('@app/UiElements/Pagination'),
  },
  mixins: [
    CatalogMixin,
  ],
  props: {
    pagination: Object,
    resultAmount: String,
    bannerImg: String,
    bannerImgWebp: String,
    authObjectItem: Object,
    cartObjectItem: Object,
  },
  data() {
    return {
      paramsObj: {},
      isLoad: false,
      isResponse: false,
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      showFilters: false,
      filters: [],
      response: '',
      slug: 'search-result',
      isAddPage: false,
      isFirstAdd: true,
      firstCards: '',
      amount: this.resultAmount,
      isSelectedArray: false,
    }
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  async mounted() {
    this.showFilters = true
    await this.checkUrl(this.slug)
    await this.prepareFilterData()
    await this.getFilters()
    // await this.getFirstCards(this.page)
  },
  methods: {
    async getCards(page = one) {
      this.isLoad = true
      if (true === this.isFirstAdd) {
        const neccessaryPage = 1 === page ? page : page - 1 // prevent request to zero page
        await this.getFirstCards(neccessaryPage)
      }
      this.paramsObj = this.removeEmpty(JSON.parse(JSON.stringify(this.paramsObj)))
      return searchCardsResource.execute({ filters: this.paramsObj, page })
        .then(async res => {
          await this.handleCombinePagination(res)
          this.page = res.meta.page
          this.count = res.meta.count
          this.perPage = res.meta.perPage
          this.amount = res.meta.count
          this.pushHistory()
          this.isResponse = true
          this.isLoad = false
        })
    },
    getFilters() {
      this.isLoad = true
      return searchFiltersResource.execute({ search: this.paramsObj.search })
        .then(res => {
          this.filters = res.data.items
          this.isLoad = false
        })
    },
    prepareFilterData() {
      this.filters = this.filters.map(el => {
        el.options.map(item => {
          item.name = el.label
          return item
        })
        return el
      })
      // open filter sections
      if (this.paramsObj?.category) {
        this.paramsObj.category.forEach(el => {
          this.filters.forEach(item => {
            item.options.forEach(option => {
              if (option.label === el) {
                item.isOpen = true
              }
            })
          })
        })
      }
      this.showFilters = true
    },
    clearFilters() {
      Object.keys(this.paramsObj).forEach(key => {
        if ('search' !== key) {
          this.paramsObj[key] = ''
        }
      })
      this.getCards()
      this.page = one
      this.isAddPage = false
      this.isFirstAdd = true
    },
    handleCombinePagination(res) {
      if (true === this.isAddPage) {
        if (true === this.isFirstAdd) this.response = this.firstCards
        res.data.forEach(el => { this.response.push(el) })
        this.isFirstAdd = false
      } else {
        this.isFirstAdd = false
        this.response = res.data
        this.firstCards = ''
      }
    },
    getFirstCards(page = one) {
      return searchCardsResource.execute({
        slug: this.category,
        filters: this.paramsObj,
        page,
      })
        .then(async res => {
          this.firstCards = res.data
        })
    },
  },
}
</script>
