import {
  postResource,
  createResource,
  // getResource,
} from '@resource/resource'
import { COMPARISON } from './paths'

export const turnComparisonResource = createResource(
  COMPARISON.TURN, postResource
)

export const deleteCompareCategoryResource = createResource(
  COMPARISON.DELETE_CATEGORY, postResource
)

export const getCompareCardsResource = createResource(
  COMPARISON.GET_CATEGORY_COMPARE, postResource
)
