import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { ACTIONS } from './paths'

export const turnFavoriteResource = createResource(
  ACTIONS.FAVORITE, postResource
)

export const cartCountResource = createResource(
  ACTIONS.CART_COUNT, getResource
)
