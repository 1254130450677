import 'vue-slider-component/theme/default.css'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import Tooltip from 'vue-directive-tooltip'
import VueSocialSharing from 'vue-social-sharing'
import GridPlugin from '@aspectus/vue-grid'
import Toasted from 'vue-toasted'
import Actions from './Actions'
import Bem from './bem'
import Cart from './Cart'
import Catalog from './Catalog'
import Configurator from './Configurator'
import Controls from './Controls'
import Forms from './Forms'
import Search from './Search'
import UiElements from './UiElements'
import Modals from './Modals'
import Order from './Order'
import Cards from './Cards'
import ExportContent from './ExportContent'
import Credit from './Credit'
import Icons from './Icons'

const VueInputMask = require('vue-inputmask').default

export default function plugin(Vue) {
  Vue.use(GridPlugin)
  Vue.use(VueSocialSharing)
  Vue.use(Tooltip)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      clickToClose: true,
      scrollable: true,
      transition: 'fade',
      overlayTransition: 'fade',
    },
  })
  Vue.use(Toasted)
  Vue.use(Actions)
  Vue.use(Bem)
  Vue.use(Cart)
  Vue.use(Catalog)
  Vue.use(Configurator)
  Vue.use(Controls)
  Vue.use(Forms)
  Vue.use(Modals)
  Vue.use(Order)
  Vue.use(Search)
  Vue.use(UiElements)
  Vue.use(Cards)
  Vue.use(VueInputMask)
  Vue.use(ExportContent)
  Vue.use(Credit)
  Vue.use(Icons)
}
