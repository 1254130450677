import { userDataResource } from '@services/cabinet.service.js'

export const cabinet = {
  namespaced: true,
  state: {
    userData: {},
  },
  getters: {
    getUserData: state => state.userData,
  },
  actions: {
    userDataRequest({ commit }) {
      return userDataResource.execute()
        .then(res => { commit('setUserData', res.data.item) })
    },
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload
    },
  },
}
