import {
  // postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { PRODUCT_CATALOG } from './paths'

export const productCatalogResource = createResource(
  PRODUCT_CATALOG.CARDS, getResource
)

export const productFiltersResource = createResource(
  PRODUCT_CATALOG.FILTERS, getResource
)
