<template lang="pug">
  include ../mixins
  div
    vue-slider.ma--bottom_sm(
      v-model="rangeValue"
      :min="options[0].min"
      :max="options[0].max"
      :lazy="true"
      :contained="true"
      @change="emitValue(); handleSelected(options[0].name)"
    )
    row
      cell.ma--bottom_sm-till-xxl(cols="12 6-xxl")
        row(align="center")
          cell(cols="narrow")
            +b.P.range-label {{_('від')}}
          cell(cols="auto")
            +b.range__input.INPUT(
              type="number"
              v-model.number="rangeValue[0]"
              :min="options[0].min"
              @blur="emitValue(); handleSelected(options[0].name)"
              @keyup.enter="emitValue(); handleSelected(options[0].name)"
            )
          cell(cols="narrow")
            +b.P.range-label {{_('грн')}}
      cell(cols="12 6-xxl")
        row(align="center")
          cell(cols="narrow")
            +b.P.range-label {{_('до')}}
          cell(cols="auto")
            +b.range__input.INPUT(
              type="number"
              v-model.number="rangeValue[1]"
              :max="options[0].max"
              @blur="emitValue(); handleSelected(options[0].name)"
              @keyup.enter="emitValue(); handleSelected(options[0].name)"
            )
          cell(cols="narrow")
            +b.P.range-label {{_('грн')}}
</template>

<script>
import VueSlider from 'vue-slider-component'
import { hasKey } from '@helpers/utils'

export default {
  name: 'range-control',
  components: {
    VueSlider,
  },
  props: {
    options: Array,
    paramsObj: Object,
    name: String,
    selectedArray: Array,
    type: String,
  },
  data() {
    return {
      rangeValue: this.options[0].value,
      params: this.paramsObj,
      selectedItems: this.selectedArray,
    }
  },
  mounted() {
    this.normalize()
  },
  watch: {
    paramsObj: {
      handler(nval) {
        this.params = nval
        this.normalize()
      },
      deep: true,
    },
    selectedArray(nval) {
      this.selectedItems = nval
      this.normalize()
    },
  },
  methods: {
    emitValue() {
      if (this.options[0].min === this.rangeValue[0] && this.options[0].max === this.rangeValue[1]) {
        delete this.params[`${this.name}_min`]
        delete this.params[`${this.name}_max`]
        this.$emit('update-params', this.params)
      } else {
        this.params[`${this.name}_min`] = [this.rangeValue[0]]
        this.params[`${this.name}_max`] = [this.rangeValue[1]]
        this.$emit('update-params', this.params)
      }
    },
    normalize() {
      if (hasKey(this.params, `${this.name}_min`)) {
        this.rangeValue = [
          Number(this.params[`${this.name}_min`][0]),
          Number(this.params[`${this.name}_max`][0]),
        ]
      } else {
        this.rangeValue = this.options[0].value
      }
    },
    handleSelected(label) {
      const selectedData = [...this.selectedItems]
      if (
        this.options[0].min === this.rangeValue[0] &&
        this.options[0].max === this.rangeValue[1]
      ) {
        const index = selectedData.findIndex(el => el.label === label)
        if (-1 !== index) {
          selectedData.splice(index, 1)
          this.$emit('update-selected', selectedData)
        }
      } else {
        const index = selectedData.findIndex(el => el.label === label)
        if (-1 !== index) {
          this.$set(selectedData, index, {
            label,
            type: this.type,
            title: `${this.rangeValue[0]}₴ - ${this.rangeValue[1]}₴`,
            name: this.name,
          })
          this.$emit('update-selected', selectedData)
        } else {
          selectedData.push({
            label,
            type: this.type,
            title: `${this.rangeValue[0]}₴ - ${this.rangeValue[1]}₴`,
            name: this.name,
          })
          this.$emit('update-selected', selectedData)
        }
      }
    },
  },
}
</script>
