<template lang="pug">
  include ../mixins
  div
    +b.collapse
      +e.wrapper(
        :style="{ maxHeight: `${blockHeight}px` }"
        :class='{"is-active": isOpened}'
      )
        div(ref="text")
          slot
      +e.button.BUTTON(
        v-if="isShowButton"
        @click="toggleCollapse"
        :class="{'is-active': isOpened}"
      )
        span(v-if="!isOpened") {{_('Дивитись більше')}}
        span(v-if="isOpened") {{_('Дивитись меньше')}}
        +e.icon
          +b.ICON-CHEVRON-DOWN.i-icon.--color_main
</template>

<script>
export default {
  name: 'ui-collapse',
  props: {
    heights: {
      type: Object,
    },
    fitBlock: Boolean,
    // bgClass: String,
  },
  data() {
    return {
      isOpened: false,
      isShowButton: false,
    }
  },
  mounted() {
    this.showButton()
  },
  methods: {
    toggleCollapse() {
      this.isOpened = !this.isOpened
    },
    showButton() {
      setTimeout(() => {
        if (this.$refs.text.clientHeight >= this.blockHeight) {
          this.isShowButton = true
        } else {
          this.isShowButton = false
        }
      }, 1000)
    },
  },
  computed: {
    blockHeight() {
      const screenWidth = Object.keys(this.heights)
      const height = Object.values(this.heights)
      if (true === this.fitBlock) {
        const bottomBlock = document.querySelector('.js-bottom-block').clientHeight
        for (let i = 0; i < screenWidth.length; i++) {
          if (window.matchMedia(`(max-width: ${screenWidth[i]}px)`).matches) {
            // calculate height of block under product description
            // offset and paddings
            const offset = 140
            return height[i] - bottomBlock - offset
          }
        }
      } else {
        for (let i = 0; i < screenWidth.length; i++) {
          if (window.matchMedia(`(max-width: ${screenWidth[i]}px)`).matches) {
            return height[i]
          }
        }
        return '0'
      }
      return '0'
    },
  },
}
</script>
