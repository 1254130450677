<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(@click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    +b.P.text.--color_accent-1.--variant_center.--variant_uppercase.--variant_bold.--appearance_title.--size_md.--height_sm.ma.--bottom_md {{ info.title }}
    +b.g-row
      +b.g-cell.g-cols--6
        +b.button--variant_border-white.--width_full.--space_3xs.--height_md(
          @click.prevent="submit(false)"
        ) {{ _("Ні") }}
      +b.g-cell.g-cols--6
        +b.button--variant_border-white.--width_full.--space_3xs.--height_md(
          @click.prevent="submit(true)"
        ) {{ _("Так") }}
</template>

<script>
export default {
  props: {
    info: {},
    promise: {},
  },

  methods: {
    submit(trigger) {
      this.promise(trigger)
      this.$emit('close')
    },
  },
}
</script>
