import BuyAction from './BuyAction'
import FavoriteButton from './FavoriteButton'
import CompareButton from './CompareButton'
import CartButton from './CartButton'
import TrackAction from './TrackAction'
import CompareAction from './CompareAction'
import FavoriteAction from './FavoriteAction'
import LogOut from './LogOut'
import DeleteTrackedAction from './DeleteTrackedAction'
import ConfiguratorAction from './ConfiguratorAction'
import ConfiguratorButton from './ConfiguratorButton'

export default function install(Vue) {
  Vue.component(BuyAction.name, BuyAction)
  Vue.component(FavoriteButton.name, FavoriteButton)
  Vue.component(CompareButton.name, CompareButton)
  Vue.component(CartButton.name, CartButton)
  Vue.component(TrackAction.name, TrackAction)
  Vue.component(CompareAction.name, CompareAction)
  Vue.component(FavoriteAction.name, FavoriteAction)
  Vue.component(LogOut.name, LogOut)
  Vue.component(DeleteTrackedAction.name, DeleteTrackedAction)
  Vue.component(ConfiguratorAction.name, ConfiguratorAction)
  Vue.component(ConfiguratorButton.name, ConfiguratorButton)
}
