<template lang="pug">
  include ../mixins
  div
    bem-text.ma--bottom_2sm(
      tag="p"
      color="black"
      size="2md"
      height="md"
    ) {{_('Вкажіть Email або номер телефону')}}
    bem-text.ma--bottom_sm(
      tag="p"
      color="black"
      size="2xs"
      height="md"
    ) {{_('Для реєстрації на сайті необхідно обов`язково вказати електронну пошту')}}
    validation-observer(
      tag="form"
      :action='completeSocial'
      method='POST'
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3xs
        d-control-input(
          rules="email_phone|required"
          type="text"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :input-label="_('Е-mail або номер телефону')"
          label-class="form__label"
          :required="true"
          name="emailPhone"
          :disabled="isCode === true && canEdit === false"
        )
        row.ma--top_xs(justify="end" v-if="canEdit === false")
          cell
            +b.link--color_accent-1.--size_2xs(@click="accessEditing") {{_('Я помилився, ввести знову')}}

      // TODO: add scroll to code
      code-form(
        ref="code"
        v-if="isCode === true && canEdit === false"
        :formdata="codeData.secretCode"
        @update="codeData.secretCode = $event"
      )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      input(
        type='hidden'
        name='csrfmiddlewaretoken'
        :value='getCookie("csrftoken")'
      )
      input(
        type='hidden'
        name='userId'
        :value='codeData.idUser'
      )
      input(
        type='hidden'
        name='emailPhone'
        :value='codeData.emailPhone'
      )
      bem-button.ma--top_sm(
        v-if="isCode === false && canEdit === true"
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @keyup.enter="validateBeforeSubmit()"
        @event="validateBeforeSubmit()"
      ) {{_('Відправити')}}
      bem-button.ma--top_sm(
        v-else
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @keyup.enter="validateBeforeSubmit(sendCode)"
        @event="validateBeforeSubmit(sendCode)"
      ) {{_('Підтвердити')}}
</template>

<script>
import {
  socialEmailPhoneResource,
  // registrationActivateResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
// import { createResource, postResource } from '@resource/resource'
// import { baseAPI } from '@resource/api.js'
import { getCookie } from '@resource/cookie'

export default {
  name: 'email-not-set-form',
  mixins: [FormMixin],
  components: {
    'code-form': () => import('@app/Forms/CodeForm'),
  },
  props: {
    redirectLink: String,
    completeSocial: String,
    partialToken: String,
  },
  data() {
    return {
      formdata: {
        emailPhone: '',
      },
      codeData: {
        secretCode: '',
        emailPhone: '',
        idUser: '',
      },
      isCode: false, // false
      canEdit: true, // -true
    }
  },
  methods: {
    getCookie,
    send(data) {
      this.fieldErrors = []
      return socialEmailPhoneResource.execute({}, data)
        .then(res => {
          this.codeData.emailPhone = res.data.item.emailPhone
          this.codeData.idUser = res.data.item.idUser
          this.isCode = true
          this.canEdit = false
        })
    },
    sendCode() {
      this.$refs.observer.$el.submit()
      // const resource = createResource(
      //   baseAPI(this.completeSocial), postResource
      // )
      // return registrationActivateResource.execute({}, this.codeData)
      //   .then(() => {
      //     window.location.replace(this.redirectLink)
      //   })
      // return resource.execute({}, { email: this.formdata.emailPhone, partial_token: this.partialToken })
      //   .then(res => {
      //     window.location.replace(this.redirectLink)
      //   })
      // return registrationActivateResource.execute({}, this.codeData)
    },
    accessEditing() {
      this.canEdit = true
      this.isCode = false
      this.codeData.secretCode = ''
    },
    scrollIntoView(item) {
      setTimeout(() => {
        item.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }, 1000)
    },
  },
}
</script>
