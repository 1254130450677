import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { REVIEW } from './paths'

export const addReviewResource = createResource(
  REVIEW.ADD, postResource
)

export const cardsReviewResource = createResource(
  REVIEW.CARDS, getResource
)
