import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Components from '@app'
import Viewer from 'v-viewer'
import { ObserveVisibility } from 'vue-observe-visibility'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(Components)
  Vue.use(Viewer, {
    defaultOptions: {
      transition: false,
      navbar: false,
      title: false,
    },
  })
  Vue.directive('observe-visibility', ObserveVisibility)
}

export default { install }
