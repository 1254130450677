<script>
import { turnComparisonResource, deleteCompareCategoryResource } from '@services/comparison.service.js'
import { djangoToJsBoolean } from '@helpers/utils'
import { mapActions } from 'vuex'
import ActionsMixin from '@mixins/actions.mixin.js'

export default {
  name: 'compare-action',
  mixins: [ActionsMixin],
  props: {
    isActive: {},
    id: {},
    item: String,
    objectKey: String,
    isDelete: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isActiveProduct: false,
    }
  },
  mounted() {
    if ('boolean' === typeof this.isActive) this.isActiveProduct = this.isActive
    else this.isActiveProduct = djangoToJsBoolean(this.isActive)
  },
  methods: {
    send() {
      let resource = turnComparisonResource
      if ('category' === this.item) resource = deleteCompareCategoryResource
      return resource.execute({}, { [this.objectKey]: this.id })
        .then(res => {
          if (true === this.isDelete) window.location.reload()
          else this.isActiveProduct = res.data.item.create
          this.changeAction({ key: 'compare', res: res.data.item.count })
          this.showNotification(this._('Додано до порівняння'), this._('Видалено з порівняння'))
        })
    },
    ...mapActions('common', [
      'changeAction',
    ]),
  },
  render() {
    return this.$scopedSlots.default({
      action: this.send,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
