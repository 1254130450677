<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      @click="$emit('close')"
    )
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="3md"
      color="black"
    ) {{_('Умови кредитування')}}
    +b.credit
      +e.item(v-if="objectItem.maxPayCount")
        credit-privatbank(
          :object-item="objectItem"
          @close="$emit('close')"
        )
      +e.item(v-if="objectItem.maxPayCountMonobank")
        credit-monobank(
          :object-item="objectItem"
          @close="$emit('close')"
        )
</template>

<script>
export default {
  name: 'credit-form',

  props: {
    objectItem: Object,
  },
}
</script>
