import SuccessModal from '@app/Modals/SuccessModal'
import ConfirmModal from '@app/Modals/Confirm'

export const openMessageModal = (info, self) => {
  self.$modal.show(SuccessModal, {
    title: info.title,
  }, {
    classes: 'modal modal--container modal--size_sm modal--border_sm modal--padding_lg',
  })
}

/**
 * @argument {object} info
 * @argument {object} self
 */
export function openConfirmModal(info, self) {
  return new Promise(resolve => {
    /**
     * @argument {boolean} arg
     */
    const promise = arg => new Promise(res => {
      if (arg) {
        res(arg)
      }
    }).then(result => {
      resolve(result)
    })
    self.$modal.show(ConfirmModal, {
      info,
      promise,
    }, {
      classes: 'modal modal--container modal--size_sm modal--border_sm modal--padding_lg',
    })
  })
}
