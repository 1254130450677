import SubscriptionForm from './SubscriptionForm'
import RegistrationForm from './RegistrationForm'
import AuthForm from './AuthForm'
import ReestablishPassword from './ReestablishPassword'
import ReestablishCode from './ReestablishCode'
import ReviewForm from './ReviewForm'
import AvailabilityForm from './AvailabilityForm'
import FindCheaperForm from './FindCheaperForm'
import ContactForm from './ContactForm'
import ChangePasswordForm from './ChangePasswordForm'
import ChangeEmailForm from './ChangeEmailForm'
import ChangePhoneForm from './ChangePhoneForm'
import EmailNotSetForm from './EmailNotSetForm'

export default function install(Vue) {
  Vue.component(SubscriptionForm.name, SubscriptionForm)
  Vue.component(RegistrationForm.name, RegistrationForm)
  Vue.component(AuthForm.name, AuthForm)
  Vue.component(ReestablishPassword.name, ReestablishPassword)
  Vue.component(ReestablishCode.name, ReestablishCode)
  Vue.component(ReviewForm.name, ReviewForm)
  Vue.component(AvailabilityForm.name, AvailabilityForm)
  Vue.component(FindCheaperForm.name, FindCheaperForm)
  Vue.component(ContactForm.name, ContactForm)
  Vue.component(ChangePasswordForm.name, ChangePasswordForm)
  Vue.component(ChangeEmailForm.name, ChangeEmailForm)
  Vue.component(ChangePhoneForm.name, ChangePhoneForm)
  Vue.component(EmailNotSetForm.name, EmailNotSetForm)
}
