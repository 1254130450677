<script>
import { cartChangeResource } from '@services/order.service.js'
import { cartData } from '@mixins/order.mixin.js'
import { mapActions } from 'vuex'

const one = 1

export default {
  name: 'buy-action',
  props: {
    productId: [Number, Array],
    objectItem: Object,
  },
  data() {
    return {
      id: this.productId,
    }
  },
  /**
   * added because of constructor.
   * selection of product didn't trigger updating
  */
  watch: {
    productId: {
      handler(nval) {
        this.id = nval
      },
    },
  },
  methods: {
    addProduct(isModal = true) {
      if (!Array.isArray(this.id)) this.id = [this.id]

      cartChangeResource.execute({}, cartData(this.id, one)).then(res => {
        const { data: { item } } = res

        this.cartCountRequest('cart')

        this.addGtagAddToCartEvent(item)

        if (isModal) this.openModal()
      })
    },

    addGtagAddToCartEvent(item) {
      const addedProducts = item.products.filter(product => {
        if (this.id.includes(product.id)) {
          return product
        }

        return false
      })

      const products = addedProducts.map(el => {
        const { code, title, prices, color, category } = el
        const { price2 } = prices

        return {
          id: code,
          name: title,
          category,
          price: price2,
          quantity: one,
          variant: color || '',
        }
      })

      const finalPrice = products.reduce((acc, el) => {
        acc += el.price * el.quantity

        return acc
      }, 0)

      /* eslint-disable-next-line */
      gtag('event', 'add_to_cart', {
        items: products,
        currency: 'UAH',
        value: Number(finalPrice),
      })
    },

    openModal() {
      this.$modal.show(() => import('@app/Modals/CommonModal'), {
        component: 'cart-modal',
        objectItem: this.objectItem,
      },
      {
        name: 'modal',
        classes: 'modal modal--size_lg modal--variant_cart modal--container modal--padding_sm modal--border_sm',
      })
    },
    ...mapActions('common', [
      'cartCountRequest',
    ]),
  },
  render() {
    return this.$scopedSlots.default({
      handle: this.addProduct,
    })
  },
}
</script>
