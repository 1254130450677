<template lang="pug">
  include ../mixins
  div
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      row.ma--bottom_sm(align="end")
        cell(cols="12 4-lg")
          +b.form.position--relative.ma--bottom_3xs
            d-control-input(
              rules="required|username"
              type="text"
              label-class="form__label"
              :input-label="_('Ваше ім`я')"
              v-model="formdata.name"
              class-name="form__input"
              :required="true"
              name="name"
            )
        cell(cols="narrow")
          validation-provider(
            tag="div"
            v-slot="{errors}"
            name="point"
            :rules="{ required_if: formdata.length === 0 }"
          )
            +b.form.position--relative.ma--bottom_3xs
              +e.label.LABEL {{_('Ваша оцінка')}}
              +b.card--border_xs.--border_gray.--space_3xs
                ui-star-rating(
                  :read-only="false"
                  :current-rating="formdata.point"
                  @rating-selected="formdata.point = $event"
                  :key="formdata.point"
                )
                  input(
                    hidden
                    v-model="formdata.point"
                  )
              p.error-text.error-text--checkbox.ma--bottom_xs.control-hint.position--absolute(
                v-if="errors[0]"
              ) {{ errors[0] }}
      +b.form.position--relative.ma--bottom_3xs
        d-control-textarea.ma--bottom_sm(
          :rules="{ required_if: formdata.point === 0 }"
          type="text"
          label-class="form__label"
          :input-label="_('Ваш відгук або запитання')"
          v-model="formdata.content"
          class-name="form__textarea"
          :required="true"
          name="content"
        )
      row(justify="end")
        cell(cols="narrow")
          bem-button(
            variant="accent-1"
            space="sm"
            width="full"
            :disabled="isLoad"
            @event="validateBeforeSubmit()"
          ) {{_('Відправити')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { addReviewResource } from '@services/review.service.js'
import { removeEmptyKeys, djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'review-form',
  mixins: [FormMixin],
  props: {
    idProduct: Number,
  },
  data() {
    return {
      formdata: {
        name: '',
        content: '',
        point: 0,
        product: this.idProduct,
      },
      isAuth: djangoToJsBoolean(window.is_auth),
    }
  },
  watch: {
    user: {
      handler(nval) {
        if (true === this.isAuth) {
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (true === this.isAuth) {
      this.userDataRequest()
    }
  },
  methods: {
    send(data) {
      const copyData = JSON.parse(JSON.stringify(data))
      return addReviewResource.execute({}, removeEmptyKeys(copyData))
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.formdata.point = 0
          this.formdata.product = this.idProduct
        })
    },
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
