export function videoControl() {
  const video = document.querySelector('.js-video')
  const control = document.querySelector('.js-control')
  if (video) {
    // if (window.matchMedia('(max-width: 1020px)').matches) {
    //   video.setAttribute('autoplay', true)
    // }
    control.addEventListener('click', () => {
      if (control.classList.contains('is-active')) {
        video.pause()
        control.classList.remove('is-active')
      } else {
        video.play()
        control.classList.add('is-active')
      }
    })
  }
}
