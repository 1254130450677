export default {
  methods: {
    showNotification(addMsg, removeMsg) {
      const message = true === this.isActiveProduct ? addMsg : removeMsg
      this.$toasted.show(message, {
        theme: 'bubble',
        position: 'top-right',
        duration: 3000,
        className: 'toaster--orange',
      })
    },
  },
}
