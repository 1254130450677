<template lang="pug">
  include ../mixins
  div
    +b.position--relative.wrap-share
      +b.share-btn.BUTTON(:class="buttonClass" @click="isShow = !isShow")
        slot
      +b.share-wrapper(:class="{'is-active': isShow}")
        .g-row.g-row--space_xs.g-row--align_center
          .g-cell
            +e.btn--facebook
              share-network(
                network="facebook"
                :url="url"
                title="Facebook"
              )
                +b.ICON-FACEBOOK.i-icon.--color_white.--size_2sm
          .g-cell
            +e.btn--twitter
              share-network(
                network="twitter"
                :url="url"
                :title="title"
              )
                +b.ICON-TWITTER.i-icon.--color_white.--size_2sm
          .g-cell
            +e.btn--viber
              share-network(
                network="viber"
                :url="url"
                :title="title"
              )
                +b.ICON-VIBER.i-icon.--color_white.--size_2sm
          .g-cell
            +e.btn--telegram
              share-network(
                network="telegram"
                :url="url"
                :title="title"
              )
                +b.ICON-TELEGRAM.i-icon.--color_white.--size_2sm
          .g-cell
            +e.btn--whatsapp
              share-network(
                network="whatsapp"
                :url="url"
                :title="title"
              )
                +b.ICON-WHATSAPP.i-icon.--color_white.--size_2sm
</template>

<script>
export default {
  name: 'ui-share',
  props: {
    buttonClass: String,
    buttonText: String,
    isBig: Boolean,
    url: String,
    title: String,
  },
  data() {
    return {
      isShow: false,
    }
  },
}
</script>
