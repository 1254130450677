import { prefixLanguage } from '@helpers/url'

const BASE_CABINET = prefixLanguage('/client/')
const baseCabinet = url => BASE_CABINET + url

export const USER_BASE_PATH = baseCabinet(BASE_CABINET)
export const USER_ORDERS_PATH = baseCabinet('order/list/')
export const USER_SETTINGS_PATH = baseCabinet('settings/')
export const USER_TICKETS_LIST_PATH = baseCabinet('ticket/list/')
export const USER_TICKET_VIEW_PATH = baseCabinet('ticket/view/:id?/')
export const USER_FAVORITES_PATH = baseCabinet('favorites/')
export const USER_TRACKED_PATH = baseCabinet('tracked/')
