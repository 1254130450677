<template lang="pug">
  include ../mixins
  buy-action(
    :product-id="objectItem.productId"
    :object-item="{linkOrder: objectItem.orderLink, linkCatalog: objectItem.linkCatalog}"
  )
    template(#default="{ handle }")
      +b.pay-parts
        +e.heading
          +e.preview-wrapper
            +e.IMG.preview(src="/static/img/pay-parts-monobank.jpg")
          +e.title
            +b.P.text.--color_black.--size_2sm.--height_sm {{_('Покупка частями monobank')}}
        +e.content
          row(align="center" justify="end")
            cell(cols="12 narrow-xl")
              +e.payment(v-if="formdata.payment && calculation.amount")
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm {{ formdata.payment.value }} &nbsp;
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm(
                  v-word-case:payment="formdata.payment.value"
                  :key="formdata.payment.value"
                )
                +b.SPAN.text.--color_secondary.--size_2sm.--height_sm &nbsp; {{_('на')}} &nbsp;
            cell(cols="12 7-xl")
              +b.form
                d-control-multiselect.order-multiselect(
                  v-model="formdata.payment"
                  track-by="value"
                  label="label"
                  name="payment"
                  label-class="form__label"
                  :options="choices.months"
                  :placeholder="_('Кількість місяців')"
                  :show-labels="false"
                  :allow-empty="true"
                  :without-label="true"
                  @input="calculatePartPayments"
                )
        +e.total
          +b.P.text.--color_black.--size_3sm.--height_sm.--variant_bold(
            v-if="formdata.payment && calculation.amount"
          ) {{ calculation.amount }} {{_('₴ / місяць')}}
        +e.button
          bem-button(
            variant="accent-1"
            width="full"
            space="sm"
            :disabled="!formdata.payment"
            @event="handleSelectedPayment(handle)"
          ) {{_('Обрати')}}
</template>

<script>
import { paypartsInfoResource } from '@services/payparts.service'
import { useErrorsGetter } from '@mixins/form.mixin.js'
import { openMessageModal } from '@helpers/submit'

export default {
  props: {
    objectItem: Object,
  },

  data() {
    return {
      formdata: {
        payment: null,
      },
      choices: {
        months: [],
      },
      calculation: {
        amount: null,
      },
    }
  },

  created() {
    this.getChoices()
  },

  methods: {
    async getChoices() {
      const formdata = {
        price: this.objectItem.price,
        parts: this.objectItem.maxPayCountMonobank,
      }

      paypartsInfoResource.execute({}, formdata).then(res => {
        const { data: { item } } = res

        this.choices.months = item.choices
      }).catch(async e => {
        this.formdata.payment = null

        const parsed = await useErrorsGetter(e)

        if (parsed.nonFieldErrors) {
          openMessageModal({ title: parsed.nonFieldErrors[0] }, this)
        }
      })
    },

    async calculatePartPayments() {
      await this.$nextTick()

      this.calculation.amount = this.formdata.payment.amount
    },

    async handleSelectedPayment(buyMethod) {
      if (this.objectItem.noRedirect) {
        this.objectItem.promise({
          partCount: this.formdata.payment.value,
          partPayment: this.calculation.amount,
          paymentBank: 'mono_pay_part',
        })

        this.$emit('close')
      } else {
        await buyMethod(false)

        localStorage.setItem('monobank_pay_count', this.formdata.payment.value)

        window.location.replace(this.objectItem.orderLink)
      }
    },
  },
}
</script>
