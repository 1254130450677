import { cartCountResource } from '@services/actions.service.js'

export const common = {
  namespaced: true,
  state: {
    actionsCount: {
      cart: {},
      favorite: '',
      compare: '',
      configurator: '',
    },
  },
  getters: {
    getCartData: state => state.actionsCount.cart,
    getActionsData: state => state.actionsCount,
  },
  actions: {
    cartCountRequest({ commit }, key) {
      return cartCountResource.execute()
        .then(res => { commit('setActionCount', { key, res: res.data.item }) })
    },
    changeAction(context, payload) {
      context.commit('setActionCount', payload)
    },
  },
  mutations: {
    setActionCount(state, payload) {
      state.actionsCount[payload.key] = payload.res
    },
  },
}
