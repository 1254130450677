<script>
export default {
  name: 'DevelopInfoModalWrapper',

  created() {
    const modal = () => import('@app/Modals/DevelopInfo')
    this.$modal.show(modal, {}, {
      clickToClose: false,
      classes: 'modal modal--size_fullscreen modal--container modal--padding_md',
    })
  },

  render() {
    return null
  },
}
</script>
