<template lang="pug">
  include ../mixins
  div
    +b.action-button(v-if="isAuth == true")
      +e.wrapper
        +e.A.button(:href="link" aria-label="favorite")
          +b.ICON-LIKE.i-icon.--color_accent-2.--size_1.--color_main
        +e.counter(v-if="countItem > 0") {{ countItem }}
    modal-handler(
      v-else
      component-name="auth-form"
      modal-class="modal modal--size_sm modal--container modal--padding_md modal--border_sm"
      :object-item="objectItem"
    )
      template(#default="{ handler }")
        +b.action-button
          +e.wrapper
            +e.button.BUTTON(@click="handler")
              +b.ICON-LIKE.i-icon.--color_accent-2.--size_1.--color_main(aria-label="favorite")
</template>

<script>
import { mapGetters } from 'vuex'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'favorite-button',
  props: {
    count: String,
    objectItem: Object,
    link: String,
  },
  data() {
    return {
      isAuth: djangoToJsBoolean(window.is_auth),
      countItem: this.count,
    }
  },
  watch: {
    'getActionsData.favorite': {
      handler(val) {
        this.countItem = val
      },
    },
  },
  computed: {
    ...mapGetters('common', [
      'getActionsData',
    ]),
  },
}
</script>
