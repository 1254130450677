<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_3sm.form-title(
      tag="h2"
      height="xs"
      color="black"
    ) {{_('Знайшли дешевше такий же товар?')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_md
        d-control-input-disappear(
          rules="username"
          type="text"
          :input-label="_('Ваше ім`я')"
          v-model="formdata.name"
          class-name="form__input"
          :required="false"
          autofocus
          name="name"
        )
      +b.form.position--relative.ma--bottom_md
        d-control-input-disappear(
          rules="email_phone|required"
          type="text"
          :input-label="_('Email або телефон')"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :required="true"
          name="emailPhone"
        )
      +b.form.position--relative.ma--bottom_md
        d-control-input-disappear(
          rules="required"
          type="text"
          :input-label="_('Посилання на товар')"
          v-model="formdata.linkOut"
          class-name="form__input"
          :required="true"
          name="linkOut"
        )
      +b.form.position--relative.ma--bottom_sm
        d-control-input-disappear(
          rules="required|decimal"
          type="text"
          :input-label="_('Ціна на товар')"
          v-model="formdata.price"
          class-name="form__input"
          :required="true"
          name="price"
        )
      +b.form.position--relative.ma--bottom_3sm
          d-control-textarea-disappear.ma--bottom_md(
            type="text"
            label-class="form__label"
            :input-label="_('Ваш коментар')"
            v-model="formdata.text"
            class-name="form__textarea"
            :required="false"
            name="text"
          )
      +b.error-text.ma--bottom_md.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Відправити')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { findCheaperRecource } from '@services/common.service.js'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'find-cheaper-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        name: '',
        emailPhone: '',
        linkOut: '',
        price: '',
        text: '',
        product: '',
      },
      isAuth: djangoToJsBoolean(window.is_auth),
      getParams: {},
    }
  },
  watch: {
    user: {
      handler(nval) {
        if (true === this.isAuth) {
          Object.keys(this.formdata).forEach(key => {
            this.formdata[key] = nval[key]
          })
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
          this.formdata.emailPhone = nval.email || nval.phone
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (true === this.isAuth) {
      this.userDataRequest()
    }
    this.parseParameters()
  },
  methods: {
    send(data) {
      this.formdata.product = this.objectItem.id
      data.getParams = this.getParams
      return findCheaperRecource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')
      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
