<template lang="pug">
  include ../mixins
  div.position--relative
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
    slot.js-slot(name="cards")
    renderer(
      :result="response"
    )
    row.ma--top_3sm.ma--top_lg-xl.ma--bottom_lg(justify="center")
      bem-button(
        variant="border-white"
        width="md"
        space="sm"
        v-if="!lastPage"
        @event="getCards()"
      ) {{_('Показати ще')}}
</template>

<script>
import { cardsReviewResource } from '@services/review.service.js'

export default {
  name: 'request-component',
  components: {
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
  },
  props: {
    last: Boolean,
    slug: String,
    resource: String,
  },
  data() {
    return {
      sendData: {},
      response: '',
      resourceObject: {
        review: cardsReviewResource,
      },
      lastPage: this.last,
      page: 2,
      isLoad: false,
    }
  },
  methods: {
    getCards() {
      this.isLoad = true
      this.createFormdata()
      return this.resourceObject[this.resource].execute(this.sendData)
        .then(res => {
          this.handleSimplePagination(res)
          this.isLoad = false
        })
    },
    createFormdata() {
      if (this.slug) this.sendData.slug = this.slug
      this.sendData.page = this.page
    },
    handleSimplePagination(res) {
      this.response += res.data
      this.lastPage = res.meta.last
      this.page++
    },
  },
}
</script>
