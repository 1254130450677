import { scrollUnlock, scrollLock } from '@helpers/scroll'
import { addLang } from '@helpers/url'
import { restoreObject, hasKey } from '@helpers/utils'

export default {
  data() {
    return {
      isShowFilters: false,
      isLoad: false,
      filters: [],
      scrollValue: 0,
    }
  },
  created() {
    // if (!window.matchMedia('(min-width: 1200px)').matches) {
    //   document.addEventListener('scroll', () => {
    //     const start = 700
    //     if (window.pageYOffset > start) {
    //       this.$refs.filterButton.classList.add('is-active')
    //       if (this.scrollValue > window.pageYOffset) {
    //         this.$refs.filterButton.classList.add('is-offset')
    //       } else {
    //         this.$refs.filterButton.classList.remove('is-offset')
    //       }
    //     } else {
    //       this.$refs.filterButton.classList.remove('is-active')
    //       this.$refs.filterButton.classList.remove('is-offset')
    //     }
    //     this.scrollValue = window.pageYOffset
    //   }, { passive: true })
    // }
  },
  async mounted() {
    this.setScreenWidth()
    window.addEventListener('resize', () => {
      this.setScreenWidth()
    }, { passive: true })
  },
  methods: {
    setScreenWidth() {
      if (window.matchMedia('(min-width: 1200px)').matches) {
        this.isShowFilters = false
      }
    },
    toggleFilter() {
      this.isShowFilters = !this.isShowFilters
      if (this.isShowFilters) {
        scrollLock()
      } else {
        scrollUnlock()
      }
    },
    pushHistory() {
      let baseUrl = `${addLang(window.location.origin)}/`
      if (this.slug) baseUrl += `${this.slug}/`
      if (this.category) baseUrl += `${this.category}/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}`)
    },
    handleCards(res) {
      this.isResponse = true
      this.page = res.meta.page
      this.count = res.meta.count
      this.perPage = res.meta.perPage
    },
    generateUrl(params) {
      let url = ''
      Object.keys(params).forEach((key, i) => {
        if (0 === i) {
          url += `${key}=${'search' === key ? params[key] : this.createStringFromArray(params[key])}`
        } else {
          url += `;${key}=${'search' === key ? params[key] : this.createStringFromArray(params[key])}`
        }
      })
      if ('' !== url) {
        url = `${url}/`
      }
      return url
    },
    removeEmpty(item) {
      Object.keys(item).forEach(el => {
        if (0 === item[el].length || '' === item[el][0]) delete item[el]
      })
      return item
    },
    createStringFromArray(arr) {
      let params = ''
      arr.forEach((el, i) => {
        if (i !== arr.length - 1) {
          params += `${el},`
        } else {
          params += `${el}`
        }
      })
      return params
    },
    checkUrl(slug) {
      const base = slug
      const pathElem = window.location.pathname.split('/')
      const params = 'page' !== pathElem[pathElem.indexOf(base) + 1] ? pathElem[pathElem.indexOf(base) + 1] : ''
      if ('' !== params) {
        this.parseUrl(params)
      }
    },
    parseUrl(params) {
      const paramsString = params
      const paramsArray = paramsString.split(';')
      const obj = {}
      paramsArray.forEach(el => {
        const valuesArray = el.split('=')[1].split(',')
        obj[el.split('=')[0]] = valuesArray
        if ('true' === valuesArray) obj[el.split('=')[0]] = valuesArray
        // collect selected items for checked section
        this.filters.forEach(item => {
          item.options.forEach(option => {
            valuesArray.forEach(value => {
              if (
                option.label === value &&
                this.selectedArray
              ) this.selectedArray.push(option)
            })
          })
        })
      })
      if (
        hasKey(obj, 'price_min') &&
        hasKey(obj, 'price_min') &&
        this.selectedArray
      ) {
        this.selectedArray.push({
          title: `${[obj.price_min]}₴ - ${[obj.price_max]}₴`,
          label: 'price',
          name: 'price',
          type: 'range',
        })
      }
      this.paramsObj = obj
      if (this.paramsObj.sort) this.sortValue = restoreObject(this.sortArray, 'value', this.paramsObj.sort[0])
    },
  },
}
