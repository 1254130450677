<template lang="pug">
  include ../mixins
  div
    +b.action-button(v-if="true")
      +e.wrapper
        +e.button.A(:href="link" aria-label="compare")
          +b.ICON-COMPARE.i-icon.--color_accent-2.--size_1.--color_main
        +e.counter(v-if="countItem > 0") {{ countItem }}
</template>

<script>
import { mapGetters } from 'vuex'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'compare-button',
  props: {
    count: String,
    objectItem: Object,
    link: String,
  },
  data() {
    return {
      isAuth: djangoToJsBoolean(window.is_auth),
      countItem: this.count,
    }
  },
  watch: {
    'getActionsData.compare': {
      handler(val) {
        this.countItem = val
      },
    },
  },
  computed: {
    ...mapGetters('common', [
      'getActionsData',
    ]),
  },
}
</script>
