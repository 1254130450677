<template lang="pug">
  include ../mixins
  div
    component(
      :is="component"
      :object-item="objectItem"
      @close="closeHandler"
    )
</template>

<script>
export default {
  name: 'common-modal',
  props: {
    component: {
      required: false,
    },
    objectItem: {
      required: false,
    },
    afterModalRequest: {
      required: false,
      type: Function,
    },
  },
  methods: {
    closeHandler() {
      this.$modal.hide('modal')
    },
  },
}
</script>
