import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { ORDER } from './paths'

export const cartChangeResource = createResource(
  ORDER.CART, postResource
)

export const cartListResource = createResource(
  ORDER.LIST, getResource
)

export const citiesListResource = createResource(
  ORDER.CITIES_LIST, postResource
)

export const npDepartmentsResource = createResource(
  ORDER.DEPARTMENTS_LIST, postResource
)

export const streetsListResource = createResource(
  ORDER.STREETS_LIST, postResource
)

export const warehousesListResource = createResource(
  ORDER.WAREHOUSES_LIST, getResource
)

export const createOrderResource = createResource(
  ORDER.ORDER_CREATE, postResource
)

export const createOrderOneClickResource = createResource(
  ORDER.ORDER_CREATE_ONE_CLICK, postResource
)

export const promocodeResource = createResource(
  ORDER.PROMOCODE, postResource
)
