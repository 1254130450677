import {
  postResource,
  createResource,
} from '@resource/resource'
import { AUTH } from './paths'

export const registrationResource = createResource(
  AUTH.REGISTRATION, postResource
)

export const registrationActivateResource = createResource(
  AUTH.ACTIVATION, postResource
)

export const repeatCodeResource = createResource(
  AUTH.SEND_CODE, postResource
)

export const signInResource = createResource(
  AUTH.SIGNIN, postResource
)

export const changeDataResource = createResource(
  AUTH.CHANGE_PHONE_EMAIL, postResource
)

export const changePasswordResource = createResource(
  AUTH.CHANGE_PASSWORD, postResource
)

export const reestablishResource = createResource(
  AUTH.REESTABLISH_PASSWORD, postResource
)

export const setNewPasswordResource = createResource(
  AUTH.SET_NEW_PASSWORD, postResource
)

export const passwordCodeResource = createResource(
  AUTH.PASSWORD_CODE, postResource
)

export const logoutResource = createResource(
  AUTH.LOGOUT, postResource
)

export const changeEmailResource = createResource(
  AUTH.CHANGE_EMAIL, postResource
)

export const setEmailCodeResource = createResource(
  AUTH.CHANGE_EMAIL_CODE, postResource
)

export const changePhoneResource = createResource(
  AUTH.CHANGE_PHONE, postResource
)

export const setPhoneCodeResource = createResource(
  AUTH.CHANGE_PHONE_CODE, postResource
)

export const socialEmailPhoneResource = createResource(
  AUTH.SOCIAL_EMAIL_PHONE_SET, postResource
)
