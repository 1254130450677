import { createStore } from '@store'
import { createRouter } from './js/router'
import { createI18n } from './i18n'

export function createApp({ Vue, Router, Store, I18n }) {
  const router = createRouter({ Vue, Router })
  const i18n = createI18n({ Vue, I18n })
  const store = createStore({ Vue, Store })
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })
  router.beforeEach((to, from, next) => {
    next()
    window.scrollTo(0, 0)
  })
  return { app, router, store, i18n }
}
