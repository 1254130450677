<template lang="pug">
  include ../mixins
  +b.video
    modal-handler(
      component-name="video-modal"
      :object-item="{src: getVideo(video)}"
      modal-class="modal modal--full-container modal--size_lg modal--container"
    )
      template(#default="{handler}")
        +e.content-wrapper--main.position--relative(@click="handler")
          picture
            source(
              v-if="previewWebp"
              type="image/webp"
              :srcset="previewWebp"
            )
            +e.img.IMG(:src='preview || getVideoPreview(video)')
          +b.small-video-button.BUTTON
            +e.button
            +e.icon
              icon-play2
          +e.bg(v-if="title && title.length")
            +b.text--color_white.--size_3sm.--height_xs.P(v-clamp="2" v-if="title && title.length") {{ title }}
</template>

<script>
export default {
  name: 'video-wrapper',
  props: {
    video: {
      type: String,
    },
    preview: {
      type: String,
    },
    previewWebp: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      videoLink: this.getVideo(this.video),
    }
  },
  methods: {
    getVideoId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      const eleven = 11
      if (match && match[2].length === eleven) {
        return match[2]
      }
      return 'error'
    },
    getVideoPreview(url) {
      const videoPreview = `//img.youtube.com/vi/${this.getVideoId(url)}/sddefault.jpg`
      return videoPreview
    },
    getVideo(el) {
      const item = `//www.youtube.com/embed/${this.getVideoId(el)}?autoplay=1`
      return item
      // this.videoLink = item
    },
  },
}
</script>
