import CookiePanel from './CookiePanel'
import UiSlider from './UiSlider'
import UiStarRating from './UiStarRating'
import LanguageControl from './LanguageControl'
import UiDropdown from './UiDropdown'
import UiHovered from './UiHovered'
import UiShare from './UiShare'
import UiCollapse from './UiCollapse'
import VideoWrapper from './VideoWrapper'
import UiLightbox from './UiLightbox'
import RequestComponent from './RequestComponent'
import UiCountdown from './UiCountdown'
import CompareTable from './CompareTable'
import ServiceTable from './ServiceTable'
import CombinePaginationRender from './CombinePaginationRender'
import ScrollTopButton from './ScrollTopButton'
import DevelopInfoModalWrapper from './DevelopInfoModalWrapper'
import TableScroller from './TableScroller'

export default function install(Vue) {
  Vue.component(CookiePanel.name, CookiePanel)
  Vue.component(UiSlider.name, UiSlider)
  Vue.component(UiStarRating.name, UiStarRating)
  Vue.component(LanguageControl.name, LanguageControl)
  Vue.component(UiDropdown.name, UiDropdown)
  Vue.component(UiHovered.name, UiHovered)
  Vue.component(UiShare.name, UiShare)
  Vue.component(UiCollapse.name, UiCollapse)
  Vue.component(VideoWrapper.name, VideoWrapper)
  Vue.component(UiLightbox.name, UiLightbox)
  Vue.component(RequestComponent.name, RequestComponent)
  Vue.component(UiCountdown.name, UiCountdown)
  Vue.component(CompareTable.name, CompareTable)
  Vue.component(ServiceTable.name, ServiceTable)
  Vue.component(CombinePaginationRender.name, CombinePaginationRender)
  Vue.component(ScrollTopButton.name, ScrollTopButton)
  Vue.component(DevelopInfoModalWrapper.name, DevelopInfoModalWrapper)
  Vue.component(TableScroller.name, TableScroller)
}
