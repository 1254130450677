<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_md(
      tag="h2"
      size="2md"
      color="black"
      height="sm"
    ) {{_('Як скористатись промокодом?')}}
    bem-text.ma--bottom_md(
      tag="h3"
      size="2sm"
      color="black"
    ) {{_('Все дуже просто')}}
    bem-text.ma--bottom_md(
      tag="p"
      size="sm"
      color="secondary"
      height="md"
      v-html="objectItem.tutorial"
    )
    bem-text(
      tag="span"
      size="2sm"
      height="sm"
      variant="bold"
      color="black"
    ) {{_('Промокод:')}} &nbsp;
    bem-text(
      tag="span"
      size="2sm"
      variant="bold"
      color="black"
    ) {{objectItem.code}}
</template>

<script>
export default {
  name: 'promocode-modal',
  props: {
    objectItem: Object,
  },
}
</script>
