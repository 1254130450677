<template lang="pug">
  include ../mixins
  +b.slick-container
    slick(
      :options='slickOptions'
      :class='classList'
      @init='emmiter'
      @afterChange="afterChange"
      ref='slick'
      :key="slickOptions.appendArrows"
    )
      slot(name='slide')
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'ui-slider',
  components: {
    Slick,
  },
  props: {
    classList: {},
    options: {},
    arrowClass: {},
    isConfigurator: {
      default: () => false,
    },
  },
  data() {
    return {
      slickOptions: this.options,
    }
  },
  mounted() {
    if (this.isConfigurator) {
      this.$refs.slick.destroy()
      this.$refs.slick.create(this.slickOptions)
    }
  },
  methods: {
    emmiter(event, slick) {
      this.$emit('init', slick)
    },
    afterChange(event, slick, current) {
      this.$emit('after-change', current)
    },
  },
}
</script>
