<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      v-if="!objectItem.close || objectItem.close === 'true'"
      @click="$emit('close')"
    )
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Увійти')}}
    row.ma--bottom_sm
      cell(cols="6")
        bem-link.display_block.text--variant_center(
          block="button"
          variant="border-white"
          border="sm"
          width="full"
          space="sm"
          :href="objectItem.facebook"
        ) {{_('Facebook')}}
      cell(cols="6")
        bem-link.display_block.text--variant_center(
          block="button"
          variant="border-white"
          border="sm"
          width="full"
          space="sm"
          :href="objectItem.google"
        ) {{_('Google')}}
    +b.ma--bottom_sm
      bem-text(
        tag="p"
        color="black"
        size="2xs"
      ) {{_('Авторизуючись на сайті Ви погоджуєтеся з')}}
      span &nbsp;
      bem-link(
        block="link"
        color="accent-1"
        size="2xs"
        :href="objectItem.privacy"
        target="_blank"
      ) {{_('політикою конфіденційності')}}
      bem-text(
        tag="span"
        color="black"
        size="2xs"
      )  &nbsp; {{_('та')}} &nbsp;
      bem-link(
        block="link"
        color="accent-1"
        size="2xs"
        :href="objectItem.rules"
        target="_blank"
      ) {{_('правилами сайту')}}
    bem-text.line-decoration.ma--bottom_sm(
      tag="p"
      color="secondary"
      size="2sm"
      :variant="['bold', 'center']"
    ) {{_('або')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3xs
        d-control-input(
          rules="email_phone|required"
          type="text"
          label-class="form__label"
          :input-label="_('Е-mail або номер телефону')"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :required="true"
          name="emailPhone"
          autofocus
        )
      +b.form.ma--bottom_2xs
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Пароль')"
          :required="true"
          name="password"
        )
      row.ma--bottom_sm(justify="end")
        cell
          +b.link--color_accent-1.--size_2xs.ma--top_xs( @click="handleReestablish()") {{_('Ви забули пароль?')}}
      +b.error-text.text--size_2xs.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.ma--bottom_sm(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Увійти')}}
      bem-button(
        variant="border-white"
        width="full"
        space="sm"
        @event="handleRegistration"
      ) {{_('Зареєструватися')}}
</template>

<script>
import {
  signInResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import RegistrationForm from './RegistrationForm'
import ReestablishPassword from './ReestablishPassword'

export default {
  name: 'auth-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        emailPhone: '',
        password: '',
      },
    }
  },
  methods: {
    send(data) {
      return signInResource.execute({}, data)
        .then(() => {
          // TODO: language
          if (this.objectItem?.redirectLink) window.location.replace(this.objectItem.redirectLink)
          else window.location.reload()
          // this.handleResponse({ modal: false, res, redirect: true })
        })
    },
    handleReestablish() {
      this.$emit('close')
      this.$modal.show(ReestablishPassword, {
        objectItem: this.objectItem,
      },
      {
        name: 'reestablish-password',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
    handleRegistration() {
      this.$emit('close')
      this.$modal.show(RegistrationForm, {
        objectItem: this.objectItem,
      },
      {
        name: 'reestablish-password',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
  },
}
</script>
