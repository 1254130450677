import VueSliderComponent from 'vue-slider-component'
import { createHOCc } from 'vue-hoc'

export const rangeDefaults = createHOCc({
  props: [
    'min',
    'max',
    'marks',
  ],
}, {
  props: {
    min() {
      return this.min || 0
    },
    max() {
      return this.max || null
    },
    marks() {
      return this.marks || []
    },
  },
})

export default rangeDefaults(VueSliderComponent)
