export function scrolledHeader() {
  const header = document.querySelector('.js-header')

  if (!header) return

  const start = 0

  function handleScroll() {
    if (window.pageYOffset > start) {
      header.classList.add('is-active')
    } else {
      header.classList.remove('is-active')
    }
  }
  document.addEventListener('scroll', handleScroll)
}
