import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { COMMON } from './paths'

export const trackAvailabilityResource = createResource(
  COMMON.TRACK_AVALIABILITY, postResource
)

export const deleteTrackResource = createResource(
  COMMON.DELETE_TRACK, postResource
)

export const findCheaperRecource = createResource(
  COMMON.FIND_CHEAPER, postResource
)

export const feedbackRecource = createResource(
  COMMON.FEED_BACK, postResource
)

export const serviceCitiesRecource = createResource(
  COMMON.SERVICE_CITIES, getResource
)

export const serviceListRecource = createResource(
  COMMON.SERVICE_LIST, getResource
)

export const headerSearchResource = createResource(
  COMMON.HEADER_SEARCH, postResource
)

export const searchFiltersResource = createResource(
  COMMON.SEARCH_FILTERS, getResource
)

export const searchCardsResource = createResource(
  COMMON.SEARCH_RESULT_CARDS, getResource
)

export const subscriptionResource = createResource(
  COMMON.SUBSCRIPTION_PATH, postResource
)
