export function videoSlider() {
  const videos = document.querySelectorAll('.js-video-wrapper')
  videos.forEach(el => {
    const video = el.querySelector('.js-video-item')
    const control = el.querySelector('.js-video-control')
    control.addEventListener('click', () => {
      if (control.classList.contains('is-active')) {
        video.pause()
        control.classList.remove('is-active')
      } else {
        video.play()
        control.classList.add('is-active')
      }
    })
  })
}
