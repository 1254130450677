<template lang="pug">
  include ../mixins
  div
    +b.container--common
      +b.card--color_white.--space_2xs.--space_2sm-lg.ma--top_sm.ma--bottom_3sm
        +b.g-row--align_center.--justify_center.--justify_between-md
          +b.g-cell.g-cols--12.--4-md.ma--bottom_sm-till-md
            +b.g-row--align_center.--space_md-xxl
              +b.g-cell
                +b.compare-icon
                  +e.logo
                    img(:src="img")
              +b.g-cell
                +b.text--color_black.--size_2md.H1 {{ title }}
          +b.g-cell.g-cols--narrow
            compare-action.position--relative.z-index--2(
              v-if="isResponse === true && tableData.items.length > 0"
              :id="category"
              :is-delete="true"
              object-key="idCategory"
              item="category"
            )
              template(#default="{ action }")
                bem-button.display_block.position--relative.z-index--2(
                  variant="light-2"
                  space="3xs"
                  width="full"
                  @event="action"
                ) {{_('Очистити порівняння')}}
    template(v-if="isResponse === true && tableData.items.length > 0")
      +b.container--common.table-controls
        +b.card--space_2xs.--color_white
          row
            cell(cols="6")
              bem-link.compare-button(
                block="button"
                :href.props="link"
                variant="accent-1"
                width="full"
              ) {{_('Додати ще один продукт')}}
            cell(cols="6")
              bem-button.compare-button(
                v-if="isAllAttrs === true"
                block="button"
                :href.props="tableData.linkToAdd"
                variant="light-2"
                width="full"
                @event="isAllAttrs = false; getCards()"
              ) {{_('Показати тільки відмінності')}}
              bem-button.compare-button(
                v-else
                block="button"
                :href.props="tableData.linkToAdd"
                variant="light-2"
                space="sm"
                width="full"
                @event="isAllAttrs = true; getCards()"
              ) {{_('Усі характеристики')}}
      +b.container--left.position--relative
        +b.compare-table
          ui-table-scroller.ma--top_sm(
            v-if="isResponse === true"
            ref="scroller"
          )
            template(#default="{ scrollWidth }")
              +e.row(:style="{ width: scrollWidth + 'px' }")
                +e.head.--controls.--shadow
                  //- +b.compare-table__inner-bg
                  .compare-table__content
                    .ma--bottom_sm
                      bem-link.compare-button(
                        block="button"
                        :href.props="link"
                        variant="accent-1"
                        space="sm"
                        width="full"
                      ) {{_('Додати ще один продукт')}}
                    bem-button.compare-button(
                      v-if="isAllAttrs === true"
                      block="button"
                      :href.props="tableData.linkToAdd"
                      variant="light-2"
                      space="sm"
                      width="full"
                      @event="isAllAttrs = false; getCards()"
                    ) {{_('Показати тільки відмінності')}}
                    bem-button.compare-button(
                      v-else
                      block="button"
                      :href.props="tableData.linkToAdd"
                      variant="light-2"
                      space="sm"
                      width="full"
                      @event="isAllAttrs = true; getCards()"
                    ) {{_('Усі характеристики')}}
                +e.head.--variant_1(v-for="(item, index) in tableData.items")
                  +b.compare-preview-card
                    +e.delete.BUTTON(@click="deleteCard(item.cardDescription.id)")
                      +b.ICON-CLOSE.i-icon.--color_black.--size_sm
                    row.ma--bottom_3sm(space="sm")
                      cell.position--relative(cols="narrow")
                        picture(v-if="item.cardDescription.imgCommon")
                          source(
                            type="image/webp"
                            :srcset="item.cardDescription.imgWebp"
                          )
                          +e.img.IMG(
                            :src="item.cardDescription.imgCommon"
                          )
                        +e.img.IMG(
                          v-else
                          :src="productPlaceholder"
                        )
                        //- +b.compare-preview-card__delivery-label-position(v-if="item.cardDescription.isFreeDeliver === true")
                        //-   +b.delivery-label
                        //-     +e.icon.ma--right_xs.ma--top_xs
                        //-     +e.text.P {{_('Безкоштовна доставка')}}
                      cell(cols="auto")
                        +e.title.ma--bottom_sm.BEM-LINK(
                          v-clamp="2"
                          block="link"
                          color="gray-1"
                          size="sm"
                          height="sm"
                          :href="item.cardDescription.link"
                        ) {{ item.cardDescription.title }}
                        //- +b.color-radio
                        //-   +b.color-radio__wrapper.g-row--align_center.ma--bottom_sm
                        //-     +e.color.ma--left_xs(
                        //-       v-for="color in item.cardDescription.colors"
                        //-       :style="{ 'background-color': color.color }"
                        //-       :class="{ 'color-radio__color--active': color.isActive }")
                        row(align="end")
                          cell(v-if="item.cardDescription.price1 === null && item.cardDescription.price2")
                            bem-text(
                              tag="p"
                              color="black"
                              size="sm"
                              variant="bold"
                            ) {{ item.cardDescription.price2 }} {{_('грн')}}
                          template(v-else-if="item.cardDescription.price1")
                            cell
                              bem-text(
                                tag="p"
                                color="secondary"
                                size="sm"
                                variant="line-through"
                              ) {{ item.cardDescription.price1 }} {{_('грн')}}
                            cell(v-if="item.cardDescription.price2")
                              bem-text(
                                tag="p"
                                color="accent-2"
                                size="sm"
                                variant="bold"
                              ) {{ item.cardDescription.price2 }} {{_('грн')}}
                    row(space="md-xl")
                      cell.ma--bottom_sm-till-md(cols="auto")
                        buy-action(
                          v-if="item.cardDescription.isAvailable === true"
                          :product-id="item.cardDescription.id"
                          :object-item="objectItem"
                        )
                          template(#default="{ handle }")
                            bem-button.display_block.position--relative.z-index--2.compare-button(
                              variant="border-white"
                              space="3xs"
                              width="full"
                              @event="handle"
                            )
                              span {{_('Додати до кошика')}}
                              +b.ICON-CART.i-icon.--size_md-sm.--color_accent-1.ma--left_xs
                        track-action(
                          v-else
                          :product-id="item.cardDescription.id"
                          :is-active="item.cardDescription.isTracker"
                        )
                          template(#default="{ openModal }")
                            bem-button.display_block.position--relative.z-index--2(
                              v-if="item.cardDescription.isTracker === true"
                              variant="border-white"
                              space="3xs"
                              width="full"
                              :disable="true"
                            )
                              row(align="center" justify="center")
                                bem-text(
                                  tag="span"
                                  variant="bold"
                                  size="2sm"
                                ) {{_('Ви вже слідкуєте')}}
                                +b.ICON-TRACKED.i-icon.--size_md-sm.--color_accent-1.ma--left_xs
                            bem-button.display_block.position--relative.z-index--2.compare-button(
                              v-else
                              variant="border-white"
                              space="3xs"
                              width="full"
                              @event="openModal"
                            ) {{_('Слідкувати за наявністю')}}
                      cell
                        favorite-action.position--relative.z-index--2(
                          :is-active="item.cardDescription.isFavorite"
                          :product-id="item.cardDescription.id"
                          :object-item="objectItem"
                        )
                          template(#default="{ action, isActive }")
                            +b.action-button.ma--bottom_2xs
                              +e.wrapper
                                +e.button-no-adapt--lg.--centered.BUTTON(
                                  aria-label="favorite"
                                  @click="action"
                                  :class="{'is-active': isActive}"
                                )
                                  +b.ICON-LIKE.i-icon.--color_accent-1.--size_md
                    //- +b.compare-table__border.ma--top_sm
              template(v-for="(attr, index) in tableData.attrs")
                +e.row(:style="{ width: scrollWidth + 'px' }")
                  +b.compare-table__head.--variant_1.--shadow
                    +e.content
                      bem-text.z-index--2.positions--relative(
                        tag="p"
                        color="black"
                        size="sm-xl"
                      ) {{ attr.title }}
                  +e.data(v-for="(item, i) in tableData.items")
                    +e.content
                      bem-text.z-index--2.positions--relative(
                        tag="p"
                        color="black"
                        size="sm-xl"
                      ) {{ item[attr.value] }}
    +b.container--common(v-else)
      bem-text(
        tag="p"
        variant="center"
        color="main"
        size="sm"
        height="sm"
      ) {{_('За вашим запитом не знайдено жодних результатів')}}

</template>

<script>
import { getCompareCardsResource, turnComparisonResource } from '@services/comparison.service.js'

export default {
  name: 'compare-table',
  props: {
    link: String,
    slug: String,
    title: String,
    img: String,
    category: String,
    objectItem: Object,
  },
  data() {
    return {
      productPlaceholder: window.productPlaceholder,
      tableData: {},
      isResponse: false,
      isAllAttrs: true,
    }
  },
  mounted() {
    this.getCards()
  },
  methods: {
    getCards() {
      return getCompareCardsResource.execute({}, { category: this.slug, isAllAttributes: this.isAllAttrs })
        .then(res => {
          this.tableData = res.data
          this.isResponse = true
          this.$nextTick(() => {
            if (!this.$refs.scroller) return

            this.$refs.scroller.checkScrollAvailability()
          })
        })
    },
    deleteCard(id) {
      return turnComparisonResource.execute({}, { idProduct: id })
        .then(() => {
          this.getCards()
        })
        // .catch(err => {
        //   return err.json()
        // })
        // .catch(err => {
        //   console.log(err)
        // })
    },
  },
}
</script>
