<template lang="pug">
  include ../mixins
  +b.section--color_white.position--relative
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
    +b.section.--space_3xs.--space_2sm-xl.--sticky-top.SECTION
      +b.container--common
        +b.card--color_light-1.--space_2xs.--space_2sm-xl
          +b.g-row
            //- cell(cols="12 auto-lg")
            //-   filtered-items(
            //-     @clear-filters="clearFilters"
            //-     :selected-array="selectedArray"
            //-     @delete-selected="isAddPage = false; deleteSelected($event)"
            //-   )
            +b.g-cell.g-cols--6.--narrow-xl
              multiselect.sort-multiselect(
                :options="sortArray"
                v-model="sortValue"
                track-by="value"
                label="title"
                name="sort"
                :allow-empty="false"
                @input="isAddPage = false; getCards()"
              )
            +b.g-cell.g-cols--6.--narrow-xl.u-display--hidden-xl
              +b.filter-btn.BUTTON(
                @click="toggleFilter"
                ref="filterButton"
              )
                +e.SPAN.text {{_('Фільтри')}}
                  +e.SPAN.icon
                    icon-filter
    +b.section--color_light.--space_2sm.SECTION
      +b.container--common
        row(space="sm-xl")
          cell(cols="12 3-xl")
            //- +b.u-display--hidden.--block-xl
            //-   slot(name="categories")
            +b.card--color_white.--space_2sm.--variant_fullheight.ma--bottom_3sm.filter-wrapper.position--relative(
              :class="{'is-active': isShowFilters == true}"
            )
              +b.u-display--hidden-xl
                ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--white')
              +b.filter-close.u-display--hidden-xl.BUTTON(@click="toggleFilter")
                +b.ICON-CLOSE.i-icon.--size_3sm.--color_main
              filter-section(
                v-if="showFilters"
                :filters="filters"
                :params-obj="paramsObj"
                :selected-array="selectedArray"
                @update-params="paramsObj = $event; getCards()"
                :is-selected-block="isSelectedArray"
                @clear-filters="clearFilters"
                @update-selected="selectedArray = $event; isAddPage = false"
              )
                template(slot="categories")
                  slot(name="categories")
              +b.filter-wrapper__button.card--color_white.--space_2xs.u-display--hidden-xl.filter-apply(:class="{'is-active': isShowFilters == true}")
                row(justify="center")
                  cell(cols="12 8-sm 4-md")
                    bem-button(
                      variant="accent-1"
                      width="full"
                      space="xs"
                      @event="toggleFilter()"
                    ) {{_('Застосувати')}}
          cell(cols="12 9-xl")
            +b.g-row.--space_2xs.--space_sm-xl.--appearance_spaced(
              v-if="!isResponse"
              ref="card"
            )
              slot(name="cards")
            +b.g-row.--space_2xs.--space_sm-xl.--appearance_spaced(
              v-if="isResponse && response.length"
            )
              cell(cols="6 4-lg" v-for="card in response")
                //-JSON response
                product-card(
                  :card-data="card"
                  :auth-object-item="authObjectItem"
                  :cart-object-item="cartObjectItem"
                )
            bem-text(
              v-if="isResponse && response.length === 0"
              tag="p"
              color="main"
              size="sm"
              variant="center"
            ) {{_('За вашим запитом не знайдено жодних результатів')}}
            row.ma--top_md(justify="center")
              cell(cols="12 9-lg 6-xxl")
                pagination(
                  v-if="count > perPage"
                  class-wrapper="pagination__wrapper--light"
                  :is-scroll="false"
                  :is-scroll-buttons="true"
                  :current='page'
                  :total='count'
                  :perPage='perPage'
                  :is-add-more="true"
                  @add="isAddPage = $event"
                  @page-changed="page = $event; getCards($event)"
                )
</template>

<script>
import CatalogMixin from '@mixins/catalog.mixin.js'
import { valueFromSelect } from '@helpers/utils'
import {
  productCatalogResource,
  productFiltersResource,
} from '@services/catalog.service.js'

const one = 1

export default {
  name: 'products-catalog',
  components: {
    'filtered-items': () => import('./FilteredItems'),
    'filter-section': () => import('./FilterSection'),
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    'product-card': () => import('@app/Cards/ProductCard'),
    pagination: () => import('@app/UiElements/Pagination'),
  },
  mixins: [
    CatalogMixin,
  ],
  props: {
    category: String,
    slug: String,
    pagination: Object,
    authObjectItem: Object,
    cartObjectItem: Object,
  },
  data() {
    return {
      paramsObj: {},
      selectedArray: [],
      isResponse: false,
      response: [],
      sortValue: { title: this._('Найпопулярнші'), value: 'popular' },
      sortArray: [
        { title: this._('Найпопулярнші'), value: 'popular' },
        { title: this._('По збільшенню ціни'), value: 'price_up' },
        { title: this._('По зменшенню ціни'), value: 'price_down' },
        { title: this._('Новинки'), value: 'new' },
        { title: this._('По рейтингу'), value: 'rating' },
      ],
      filters: [],
      showFilters: false,
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      isAddPage: false,
      isFirstAdd: true,
      firstCards: '',
      isSelectedArray: true,
    }
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  async mounted() {
    await this.getFilters()
    await this.checkUrl(this.category)
    await this.prepareFilterData()
  },
  methods: {
    getFilters() {
      return productFiltersResource.execute({
        slug: this.category,
        filters: this.paramsObj,
      })
        .then(res => {
          this.filters = res.data.items
        })
    },
    async getCards(page = one) {
      this.isLoad = true
      if (true === this.isFirstAdd && true === this.isAddPage) {
        const neccessaryPage = 1 === page ? page : page - 1 // prevent request to zero page
        await this.getFirstCards(neccessaryPage)
      }
      this.paramsObj = this.removeEmpty(JSON.parse(JSON.stringify(this.paramsObj)))
      this.paramsObj.sort = [valueFromSelect(this.sortValue, 'value')]
      return productCatalogResource.execute({
        slug: this.category,
        filters: this.paramsObj,
        page,
      })
        .then(async res => {
          await this.handleCombinePagination(res)
          await this.handleCards(res)
          await this.pushHistory()
          await this.handleResponse()
        })
    },
    prepareFilterData() {
      this.filters = this.filters.map(el => {
        el.options.map(item => {
          item.name = el.label
          if ('range-component' !== el.type) this.$set(item, 'checked', true)
          return item
        })
        return el
      })
      // open filter sections
      Object.keys(this.paramsObj).forEach(el => {
        this.filters.forEach(item => {
          if (el.split('_')[0] === item.label) {
            item.isOpen = true
          }
        })
      })
      this.showFilters = true
    },
    clearFilters() {
      this.paramsObj = {}
      this.selectedArray = []
      this.getCards()
      this.isAddPage = false
      this.isFirstAdd = true
    },
    deleteSelected(obj) {
      Object.keys(this.paramsObj).forEach(el => {
        const index = this.paramsObj[el].indexOf(obj.label)
        if (-1 !== index) {
          this.paramsObj[el].splice(index, 1)
          if (0 === this.paramsObj[el].length) delete this.paramsObj[el]
        }
        if (obj.type && 'range' === obj.type) {
          if (el.includes('_min') || el.includes('_max')) delete this.paramsObj[el]
        }
      })
      this.selectedArray = this.selectedArray.filter(el => el.label !== obj.label)
      this.getCards()
    },
    // get first cards onload, the same as slot contains
    getFirstCards(page = one) {
      return productCatalogResource.execute({
        slug: this.category,
        filters: this.paramsObj,
        page,
      })
        .then(async res => {
          this.firstCards = res.data
        })
    },
    handleCombinePagination(res) {
      if (true === this.isAddPage) {
        if (true === this.isFirstAdd) this.response = this.firstCards
        res.data.forEach(el => { this.response.push(el) })
        this.isFirstAdd = false
      } else {
        this.isFirstAdd = false
        this.response = res.data
        this.firstCards = ''
      }
    },
    handleResponse() {
      this.isProcessingClear = false
      this.isLoad = false
    },
  },
}
</script>
