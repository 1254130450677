<template lang="pug">
  include ../mixins
  vue-countdown(
    :time="time"
    v-slot="{ days, hours, minutes}"
    :transform="transformSlotProps"
  )
    +b.countdown
      row(space="sm")
        cell
          +e.wrapper
            +e.text {{ days }}
          bem-text.ma--top_2xs(
            tag="p"
            color="secondary"
            size="2xs"
          ) {{_('дні')}}
        +e.text.ma--top_2xs :
        cell
          +e.wrapper
            +e.text {{ hours }}
          bem-text.ma--top_2xs(
            tag="p"
            color="secondary"
            size="2xs"
          ) {{_('години')}}
        +e.text.ma--top_2xs :
        cell
          +e.wrapper
            +e.text {{ minutes }}
          bem-text.ma--top_2xs(
            tag="p"
            color="secondary"
            size="2xs"
          ) {{_('хвилини')}}
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'ui-countdown',
  components: {
    VueCountdown,
  },
  props: {
    time: Number,
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {}
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = 10 > value ? `0${value}` : String(value)
      })
      return formattedProps
    },
  },
}
</script>
