import { disableAnimation } from './disable-animation'
import { videoControl } from './video'
import { mobileMenuToggler } from './menu'
import { setTableWrap } from './table-wrap'
// import { setFixedHeader } from './fixed-header'
import { scrolledHeader } from './scrolledHeader'
import { videoSlider } from './videos'
import { sliderSnap } from './slider-snap'

document.addEventListener('DOMContentLoaded', () => {
  disableAnimation()
  // setFixedHeader()
  scrolledHeader()
  videoControl()
  mobileMenuToggler()
  setTableWrap()
  videoSlider()
  sliderSnap()
}, { passive: true })
