import ModalHandler from './ModalHandler'
import CommonModal from './CommonModal'
import VideoModal from './VideoModal'
import PromocodeModal from './PromocodeModal'

export default function install(Vue) {
  Vue.component(ModalHandler.name, ModalHandler)
  Vue.component(CommonModal.name, CommonModal)
  Vue.component(VideoModal.name, VideoModal)
  Vue.component(PromocodeModal.name, PromocodeModal)
}
