<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Реєстрація')}}
    row.ma--bottom_sm
      cell(cols="6")
        bem-link.display_block.text--variant_center(
          block="button"
          variant="border-white"
          border="sm"
          width="full"
          space="sm"
          :href="objectItem.facebook"
        ) {{_('Facebook')}}
      cell(cols="6")
        bem-link.display_block.text--variant_center(
          block="button"
          variant="border-white"
          border="sm"
          width="full"
          space="sm"
          :href="objectItem.google"
        ) {{_('Google')}}
    +b.ma--bottom_sm
      bem-text(
        tag="p"
        color="black"
        size="2xs"
      ) {{_('Авторизуючись на сайті Ви погоджуєтеся з')}}
      span &nbsp;
      bem-link(
        block="link"
        color="accent-1"
        size="2xs"
        :href="objectItem.privacy"
        target="_blank"
      ) {{_('політикою конфіденційності')}}
      bem-text(
        tag="span"
        color="black"
        size="2xs"
      )  &nbsp; {{_('та')}} &nbsp;
      bem-link(
        block="link"
        color="accent-1"
        size="2xs"
        :href="objectItem.rules"
        target="_blank"
      ) {{_('правилами сайту')}}
    bem-text.line-decoration.ma--bottom_sm(
      tag="p"
      color="secondary"
      size="2sm"
      :variant="['bold', 'center']"
    ) {{_('або')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_3xs
        d-control-input(
          rules="required|username"
          type="text"
          label-class="form__label"
          :input-label="_('Ваше ім`я')"
          v-model="formdata.firstName"
          class-name="form__input"
          :required="true"
          name="firstName"
          :disabled="isCode === true"
          autofocus
        )
      +b.form.position--relative.ma--bottom_3xs
        d-control-input(
          rules="required|username"
          type="text"
          label-class="form__label"
          :input-label="_('Ваше прізвище')"
          v-model="formdata.lastName"
          class-name="form__input"
          :required="true"
          name="lastName"
          :disabled="isCode === true"
        )
      +b.form.position--relative.ma--bottom_3xs(v-show="!isNewEmailPhone")
        row(align="start" space="2xs")
          cell
            +e.label.LABEL {{_('Е-mail або номер телефону')}}
              span.text--color_accent-1 &nbsp;*
          cell
            +b.ICON-INFO.i-icon.--size_2sm.--color_accent-1(
              v-tooltip.auto.ios.hover="{content: message}"
            )
        d-control-pure-input(
          rules="email_phone|required"
          type="text"
          v-model="formdata.emailPhone"
          class-name="form__input"
          :required="false"
          name="emailPhone"
          :disabled="isCode === true && canEdit === false"
        )
        row(justify="end" v-if="canEdit === false")
          cell
            +b.link--color_accent-1.--size_2xs(@click="accessEditing") {{_('Я помилився, ввести знову')}}

      +b.form.position--relative.ma--bottom_3xs(v-show="isNewEmailPhone")
        row(align="start" space="2xs")
          cell
            +e.label.LABEL(for="newEmailPhone") {{_('Е-mail або номер телефону')}}
              span.text--color_accent-1 &nbsp;*
          cell
            +b.ICON-INFO.i-icon.--size_2sm.--color_accent-1(
              v-tooltip.auto.ios.hover="{content: message}"
            )
        d-control-pure-input(
          rules="email_phone|required_if:isNewEmailPhone"
          type="text"
          v-model="formdata.newEmailPhone"
          class-name="form__input"
          :required="false"
          id="newEmailPhone"
          name="newEmailPhone"
          :disabled="isCode === true && canEdit === false"
        )
        row(justify="end" v-if="canEdit === false")
          cell
            +b.link--color_accent-1.--size_2xs(@click="accessEditing") {{_('Я помилився, ввести знову')}}

      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Пароль')"
          :required="true"
          name="password"
          :disabled="isCode === true"
        )
      bem-text.ma--bottom_sm(
        tag="p"
        v-if="isCode === true && canEdit === false"
        size="2xs"
        color="main"
        height="sm"
      ) {{_('На Вашу пошту або номер телефону був відправлений перевірочний код, введіть його в поле нижче')}}
      +b.form.ma--bottom_xs(ref="code")
        d-control-pure-input(
          v-if="isCode === true && canEdit === false"
          rules="required"
          type="text"
          v-model="codeData.secretCode"
          class-name="form__input"
          :required="true"
          name="secretCode"
          @keyup.enter="validateBeforeSubmit(sendCode)"
          :placeholder="_('Введіть перевірочний код *')"
        )
      row.ma--bottom_sm(justify="end" v-if="isCode === true && canEdit === false")
        cell
          bem-text(
            tag="p"
            block="link"
            color="accent-1"
            size="2xs"
            @event="repeatCode()"
          ) {{_('Відправити код знов')}}
      +b.ma--bottom_sm
        bem-text(
          tag="p"
          color="black"
          size="2xs"
        ) {{_('Авторизуючись на сайті Ви погоджуєтеся з')}}
        span &nbsp;
        bem-link(
          block="link"
          color="accent-1"
          size="2xs"
          :href="objectItem.privacy"
          target="_blank"
        ) {{_('політикою конфіденційності')}}
        bem-text(
          tag="span"
          color="black"
          size="2xs"
        )  &nbsp; {{_('та')}} &nbsp;
        bem-link(
          block="link"
          color="accent-1"
          size="2xs"
          :href="objectItem.rules"
          target="_blank"
        ) {{_('правилами сайту')}}
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      +b.g-row.--appearance_spaced
        +b.g-cell.g-cols--12.--7-sm
          bem-button.button--variant_6(
            v-if="isCode === true && canEdit === false"
            variant="accent-1"
            width="full"
            space="sm"
            :disabled="isLoad"
            @event="validateBeforeSubmit(sendCode)"
          ) {{_('Підтвердити')}}
          bem-button.button--variant_6(
            v-else
            variant="accent-1"
            width="full"
            space="sm"
            :disabled="isLoad"
            @event="validateBeforeSubmit()"
          ) {{_('Зареєструватися')}}
        +b.g-cell.g-cols--12.--5-sm
          bem-button.button--variant_6(
            variant="border-white"
            width="full"
            space="sm"
            @event="handleAuth()"
          ) {{_('Увійти')}}
</template>

<script>
import {
  registrationResource,
  registrationActivateResource,
  repeatCodeResource,
  changeDataResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import AuthForm from './AuthForm'

export default {
  name: 'registration-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        firstName: '',
        lastName: '',
        emailPhone: '',
        newEmailPhone: '',
        password: '',
      },
      codeData: {
        secretCode: '',
      },
      isCode: false,
      canEdit: true,
      isNewEmailPhone: false,
      message: this._('Email або номер телефону використовується як логін при авторизації'),
      isRepeatRegistration: false,
      oldEmailPhone: '',
      sessionId: '',
    }
  },
  methods: {
    send(data) {
      this.fieldErrors = []
      const sendData = JSON.parse(JSON.stringify(data))
      let resource = registrationResource
      if (this.isRepeatRegistration) {
        resource = changeDataResource
        sendData.oldEmailPhone = sendData.emailPhone
        sendData.emailPhone = this.oldEmailPhone
        sendData.idSession = this.sessionId
      }
      return resource.execute({}, sendData)
        .then(({ data: { item } }) => {
          this.isCode = true
          this.canEdit = false
          this.isNewEmailPhone = true
          this.formdata.newEmailPhone = item.emailPhone
          this.codeData.idUser = item.idUser
          this.oldEmailPhone = item.emailPhone
          this.sessionId = item.sessionId
          this.scrollIntoView(this.$refs.code)
        })
    },
    sendCode() {
      this.codeData.emailPhone = this.formdata.emailPhone
      return registrationActivateResource.execute({}, this.codeData)
        .then(() => {
          if (this.objectItem?.redirectLink) window.location.replace(this.objectItem.redirectLink)
          else window.location.reload()
        })
    },
    repeatCode() {
      return repeatCodeResource.execute({}, { emailPhone: this.formdata.emailPhone })
        .then(() => {
          this.notification(this._('Код відправлено'))
        })
    },
    accessEditing() {
      this.canEdit = true
      this.isRepeatRegistration = true
      this.codeData.secretCode = ''
    },
    handleAuth() {
      this.$emit('close')

      this.$modal.show(AuthForm, {
        objectItem: this.objectItem,
      },
      {
        name: 'auth-form',
        classes: 'modal modal--size_sm modal--container modal--padding_md',
      })
    },
    scrollIntoView(item) {
      setTimeout(() => {
        item.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }, 1000)
    },
  },
}
</script>
