<template lang="pug">
  include ../mixins
  +b.position--relative
    +b.search
      +e.input-back(:class="{'is-active': isActive === true || isResponse === true}")
      +e.wrapper.position--relative(:class="[{ 'is-active': isActive === true }, { 'is-result': isResponse === true && isActive === true}]")
        +e.icon--left
          +b.ICON-SEARCH.i-icon.--color_black.--size_2sm
        +e.input.INPUT(
          type="text"
          inputmode="search"
          :placeholder="labels.search"
          v-model="searchValue"
          maxlength="50"
          @keyup="handleSearch"
          @keyup.enter="redirect()"
          @focus="openSearch"
        )
        +e.icon--right.pointer.BUTTON(
          v-if="isActive"
          @click="closeSearch"
        )
          +b.ICON-CLOSE.i-icon.--color_black.--size_2sm
        +e.icon--load.spinner--xs(v-if="isLoad")
        +b.search-result(v-if="isResponse && isActive")
          +e.wrapper
            template(v-for="item in result")
              +e.item.--variant_1.display_block.A(:href="item.link")
                .g-row.g-row--align_center
                  .g-cell.g-cols.g-cols--narrow
                    +e.img.IMG(:src="item.src")
                  .g-cell.g-cols.g-cols--auto
                    +e.title {{ item.titleProd }}
            +e.item.--variant_2
              +b.link--color_accent-1.--variant_center.--size_3xs.BUTTON(
                @click="redirect()"
                v-if="isLimit === false && isResponse === true"
              ) {{_('Подивитися усі результати пошуку')}} : {{ count }}
              +b.text--color_secondary.--size_3xs.P(
                v-if="result.length == 0 && isResponse === true"
              ) {{_('Пошук не дав результатів')}}
</template>

<script>
import { headerSearchResource } from '@services/common.service.js'

export default {
  name: 'header-search',
  data() {
    return {
      searchValue: '',
      isActive: false,
      isLoad: false,
      isResponse: false,
      result: [],
      isLimit: false,
      count: null,
      labels: {
        search: this._('Пошук'),
      },
    }
  },
  props: {
    link: String,
  },
  methods: {
    openSearch() {
      this.isActive = true
    },
    closeSearch() {
      this.isActive = false
      this.searchValue = ''
      this.result = []
    },
    handleSearch() {
      if (2 < this.searchValue.length) {
        this.send()
      } else if (0 === this.searchValue.length) {
        this.isResponse = false
        this.result = []
      }
    },
    send() {
      this.isLoad = true
      return headerSearchResource.execute({}, { search: this.searchValue })
        .then(res => {
          this.result = res.data.items
          this.isResponse = true
          this.isLoad = false
          this.isLimit = res.data.isLimit
          this.count = res.data.count
        })
    },
    redirect() {
      const path = `${this.link}search=${this.searchValue}/`
      window.location.replace(path)
    },
  },
}
</script>
