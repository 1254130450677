<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    row(space="md-xxl")
      cell(cols="12 4-md")
        +b.form.position--relative.ma--bottom_3xs
          d-control-input(
            rules="required|username"
            type="text"
            label-class="form__label"
            :input-label="_('Ваше ім`я')"
            v-model="formdata.name"
            class-name="form__input"
            :required="true"
            name="name"
          )
      cell(cols="12 4-md")
        +b.form.position--relative.ma--bottom_3xs
          d-control-input(
            :rules="{ required_if: formdata.email === '' }"
            type="tel"
            inputmode="tel"
            label-class="form__label"
            :input-label="_('Ваш телефон')"
            v-model="formdata.phone"
            class-name="form__input"
            :required="false"
            mask="+38(###)###-##-##"
            name="phone"
          )
      cell(cols="12 4-md")
        +b.form.position--relative.ma--bottom_2sm
          d-control-input(
            :rules="{ required_if: formdata.phone === '', email: true }"
            type="email"
            inputmode="email"
            label-class="form__label"
            :input-label="_('Ваш Email')"
            v-model="formdata.email"
            class-name="form__input"
            :required="false"
            name="email"
          )
      cell(cols="12")
        +b.form.position--relative.ma--bottom_2sm
          d-control-textarea(
            rules="required"
            label-class="form__label"
            :input-label="_('Ваше питання')"
            v-model="formdata.message"
            class-name="form__textarea"
            :required="true"
            name="message"
          )
    +b.error-text.ma--bottom_sm.--top_sm.P(
      v-for="error in fieldErrors"
      v-if="fieldErrors.length > 0"
    ) {{ error }}
    row(justify="end")
      cell(cols="12 4-sm")
        bem-button(
          variant="border-white"
          width="full"
          space="sm"
          :disabled="isLoad"
          @event="validateBeforeSubmit()"
        ) {{_('Відправити')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { feedbackRecource } from '@services/common.service.js'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'contact-form',
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isAuth: djangoToJsBoolean(window.is_auth),
      getParams: {},
    }
  },
  watch: {
    user: {
      handler(nval) {
        if (true === this.isAuth) {
          Object.keys(this.formdata).forEach(key => {
            this.formdata[key] = nval[key]
          })
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (true === this.isAuth) {
      this.userDataRequest()
    }
    this.parseParameters()
  },
  methods: {
    send(data) {
      data.getParams = this.getParams

      return feedbackRecource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')
      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
