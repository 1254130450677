<template lang="pug">
include ../mixins
+b.scroll-top(
  @click.prevent="scrollTop"
  :class="{'is-active': isVisible}"
)
  +b.ICON-CHEVRON-UP2.i-icon.--color_white.--size_3md
</template>

<script>
export default {
  name: 'scroll-top-button',

  data() {
    return {
      isVisible: false,
      scrollingValue: null,
    }
  },

  created() {
    document.onscroll = () => {
      this.updateScrollTopVisibility()
    }
  },

  methods: {
    updateScrollTopVisibility() {
      const offset = 100
      if (window.pageYOffset > offset) {
        if (this.scrollingValue > window.pageYOffset) {
          this.isVisible = true
        } else {
          this.isVisible = false
        }
      } else {
        this.isVisible = false
      }
      this.scrollingValue = window.pageYOffset
    },

    scrollTop() {
      const offset = 0
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    },
  },
}
</script>
