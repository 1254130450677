<template lang="pug">
  include ../mixins
  div
    row.ma--bottom_sm(v-for="option in options" :key="option.label" align="center")
      cell
        input(
          :type="type"
          v-model='checkedItem'
          :id="option.label"
          :name="option.name"
          :value="option.label"
          @change="emitValue(); handleSelected(option.name, option.title, option.label)"
        )
      cell
        label.pointer(:for="option.label") {{ option.title }}
</template>

<script>
import { hasKey, isObjInArray } from '@helpers/utils'

export default {
  name: 'radio-control',
  props: {
    options: Array,
    type: String,
    paramsObj: Object,
    name: String,
    selectedArray: Array,
  },
  data() {
    return {
      checkedItem: '',
      selectedItems: this.selectedArray,
      params: this.paramsObj,
    }
  },
  watch: {
    paramsObj: {
      handler(nval) {
        this.params = nval
        this.normalize(this.params)
      },
      deep: true,
    },
    selectedArray(nval) {
      this.selectedItems = nval
      this.normalize(this.params)
    },
  },
  mounted() {
    this.normalize(this.params)
  },
  methods: {
    normalize(val) {
      this.checkedItem = hasKey(val, this.name)
        ? val[this.name][0]
        : ''
    },
    emitValue() {
      this.params[this.name] = [this.checkedItem]
      this.$emit('update-params', this.params)
    },
    handleSelected(name, title, label) {
      if ('radio' === this.type && isObjInArray(this.options, 'label', label)) {
        const index = this.selectedItems.findIndex(el => el.name === name)
        if (-1 !== index) {
          this.$set(this.selectedItems, index, { title, label, name })
          this.$emit('emit-selected', this.selectedItems)
        } else {
          this.selectedItems.push({ title, label, name })
          this.$emit('emit-selected', this.selectedItems)
        }
      } else {
        this.selectedItems.push({ title, label, name })
        this.$emit('emit-selected', this.selectedItems)
      }
    },
  },
}
</script>
