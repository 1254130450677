<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
      +b.ICON-CLOSE.i-icon.--size_md
    bem-text.ma--bottom_sm(
      tag="h2"
      size="2md"
      color="black"
    ) {{_('Встановіть новий пароль')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm(v-if="objectItem.isSetPassword")
        d-control-input(
          rules="required|min:8"
          ref='oldPassword'
          type="password"
          v-model="formdata.oldPassword"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Вкажіть старий пароль')"
          :required="true"
          name="oldPassword"
          autofocus
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8"
          ref='newPassword1'
          type="password"
          v-model="formdata.newPassword1"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Вкажіть новий пароль')"
          :required="true"
          name="newPassword1"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8|confirmed:newPassword1"
          type="password"
          v-model="formdata.newPassword2"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Вкажіть новий пароль ще раз')"
          :required="true"
          name="newPassword2"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        variant="accent-1"
        width="full"
        space="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Зберегти')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { changePasswordResource } from '@services/auth.service.js'

export default {
  name: 'change-password-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
      },
    }
  },
  methods: {
    send(data) {
      return changePasswordResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
  },
}
</script>
