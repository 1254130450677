<template lang="pug">
  include ../mixins
  div(v-if="isCartResponse")
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      row(space="sm-xl md-xxl")
        cell(cols="12 9-xl")
          +b.card--color_white.--space_3xs.--space_2sm-lg(:class="{'ma--bottom_sm': isAuth === false }")
            row(align="center")
              cell(cols="narrow")
                +b.rounded-icon--2xs.--light-2
                  bem-text(
                    tag="p"
                    color="black"
                    size="sm"
                  ) 1
              cell(cols="auto")
                bem-text(
                  tag="p"
                  color="black"
                  size="md"
                  height="sm"
                ) {{_('Контактні дані')}}
          +b.border(v-if="isAuth === true")
          row(v-if="isAuth === false")
            cell(cols="narrow")
              +b.order-tabs.BUTTON(
                :class="{'is-active': selectedTab === 'new'}"
                @click.prevent="selectTab('new')"
              )
                bem-text(
                  tag="p"
                  size="2sm"
                  weight="medium"
                  color="black"
                ) {{_('Новий покупець')}}

            cell(cols="narrow")
              +b.order-tabs.BUTTON(
                :class="{'is-active': selectedTab === 'regular'}"
                @click.prevent="selectTab('regular')"
              )
                bem-text(
                  tag="p"
                  size="2sm"
                  weight="medium"
                  color="black"
                ) {{_('Постійний покупець')}}
          +b.card--color_white.--space_3xs.--space_2sm-lg.ma--bottom_sm.--borrom_md-lg
            component(
              :is="selectedTab === 'new' ? 'order-contact-form' : 'order-auth-form'"
              :order-data="formdata"
              @update="contactDataChangeHandler"
              :is-auth="isAuth"
              :object-item="objectItem"
            )
            div(:class="{ 'ma--top_3xs': !isAuth }")
              +b.g-row.--align_center
                +b.g-cell.g-cols.--narrow
                  input(
                    type="checkbox"
                    v-model="formdata.isRecipient"
                    id="isRecipient"
                  )
                +b.g-cell.g-cols.--auto
                  label.pointer(for="isRecipient") {{ _("Отримувач не я") }}
          +b.card--color_white.--space_3xs.--space_2sm-lg.ma--bottom_sm.--borrom_md-lg(
            v-if="formdata.isRecipient"
          )
            bem-text.ma--bottom_sm(
              tag="p"
              color="secondary"
              size="3sm"
              height="sm"
            ) {{_('Введіть контактні дані отримувача')}}
            order-recipient-form(
              :order-data="formdata"
              @update="formdata = $event"
            )
          //-delivery
          +b.card--color_white.--space_3xs.--space_2sm-lg
            row(align="center")
              cell(cols="narrow")
                +b.rounded-icon--2xs.--light-2
                  bem-text(
                    tag="p"
                    color="black"
                    size="sm"
                  ) 2
              cell(cols="auto")
                bem-text(
                  tag="p"
                  color="black"
                  size="md"
                  height="sm"
                ) {{_('Виберіть зручний спосіб доставки')}}
          +b.border
          +b.card--color_white.--space_3xs.--space_2sm-lg.ma--bottom_sm.--borrom_md-lg
            order-delivery(
              :formdata="formdata"
              :cities-list="citiesList"
              @change="deliveryChangeEvent"
            )
          //-payment
          +b.card--color_white.--space_3xs.--space_2sm-lg
            row(align="center")
              cell(cols="narrow")
                +b.rounded-icon--2xs.--light-2
                  bem-text(
                    tag="p"
                    color="black"
                    size="sm"
                  ) 3
              cell(cols="auto")
                bem-text(
                  tag="p"
                  color="black"
                  size="md"
                  height="sm"
                ) {{_('Виберіть зручний спосіб оплати')}}
          +b.border
          +b.card--color_white.--space_3xs.--space_2sm-lg.ma--bottom_sm.--borrom_md-lg
            order-payment(
              :formdata="formdata"
              :is-pay-part="cartData.isPayParts"
              :is-pay-part-monobank="cartData.isMonobankPayPart"
              :pay-parts-count="cartData.payPartsCount"
              :pay-parts-count-monobank="cartData.monobankPayPartsCount"
              :cart-price="computedFinalPrice"
              :is-apply-promocode="isApplyPromo"
              @preselect-payment="preselectPayment"
              @change="paymentChangeEvent"
            )
          template(v-if="isPromo")
            //-promocode
            +b.card--color_white.--space_3xs.--space_2sm-lg
              row(align="center")
                cell(cols="narrow")
                  +b.rounded-icon--2xs.--light-2
                    bem-text(
                      tag="p"
                      color="black"
                      size="sm"
                    ) 4
                cell(cols="auto")
                  bem-text(
                    tag="p"
                    color="black"
                    size="md"
                    height="sm"
                  ) {{_('За наявності застосуйте Ваш промокод')}}
            +b.border
            +b.card--color_white.--space_3xs.--space_2sm-lg.ma--bottom_sm.--borrom_md-lg
              order-promocode(
                :pay-part="'pay_part' === formdata.methodPay"
                :method-pay="formdata.methodPay"
                :cart-data="cartData"
                @update-discount="handlePromocode($event)"
                @promocode="formdata.promocode = $event"
              )
        cell.ma--bottom_sm(cols="12 6-md 3-xl")
          div.order-aside
            bem-text.ma--bottom_3sm(
              tag="h2"
              color="black"
              size="2md"
              height="sm"
            ) {{_('Товари Вашого замовлення')}}
            modal-handler(
              component-name="cart-modal"
              modal-class="modal modal--size_cart modal--variant_cart modal--container modal--padding_sm modal--border_sm"
              :object-item="{ linkCatalog: categoryListLink, linkOrder }"
            )
              template(#default="{ handler }")
                bem-button.ma--bottom_3sm(
                  block="link"
                  color="accent-1"
                  size="2sm"
                  @event="handler"
                ) {{_('Редагувати замовлення')}}
            order-item.ma--bottom_2sm(
              v-for="product in cartData.products"
              :key="product.id"
              :product-data="product"
            )
            +b.card--color_white.--space_3xs.--space_2sm-lg
              row(
                v-if="promocodeDiscount"
                justify="center"
              )
                cell(cols="auto")
                  bem-text.ma--bottom_3xs(
                    tag="p"
                    color="black"
                    size="2sm"
                  ) {{_('Загальна вартість:')}}
                cell(cols="narrow")
                  bem-text.ma--bottom_3xs(
                    tag="p"
                    color="black"
                    size="2sm"
                  ) {{ cartData.full }} {{_('грн')}}
              row(
                v-if="promocodeDiscount"
                justify="between"
              )
                cell(cols="auto")
                  bem-text.ma--bottom_2sm(
                    tag="p"
                    color="accent-2"
                    size="2xs"
                  ) {{_('Знижка по промокоду:')}}
                cell(cols="narrow")
                  bem-text.ma--bottom_2sm(
                    tag="p"
                    color="accent-2"
                    size="2xs"
                  ) {{ promocodeDiscount }} {{_('грн')}}
              row.ma--bottom_sm(justify="between")
                cell(cols="auto")
                  bem-text(
                    tag="p"
                    color="black"
                    :variant="['bold']"
                    size="2sm"
                  ) {{_('Сума до сплати:')}}
                cell(cols="narrow")
                  bem-text(
                    tag="p"
                    color="black"
                    variant="bold"
                    size="2sm"
                  ) {{ computedFinalPrice }} {{_('грн')}}
              +b.form.position--relative.ma--bottom_3xs
                +b.g-row.--apperance_nowrap.--align_center
                  +b.g-cell.g-cols.--narrow
                    input(
                      type="checkbox"
                      v-model="formdata.is_not_call"
                      id="is_not_call"
                    )
                  +b.g-cell.g-cols.--auto
                    label.pointer(for="is_not_call") {{ _("Не перезванивать") }}
              +b.form.position--relative.ma--bottom_3xs
                d-control-textarea(
                  label-class="form__label"
                  :input-label="_('Коментар до замовлення')"
                  v-model="formdata.comment"
                  class-name="form__textarea"
                  spellcheck="true"
                  :required="false"
                  name="comment"
                )
              bem-button.ma--bottom_sm(
                variant="accent-1"
                width="full"
                space="sm"
                :disabled="isLoad"
                @event="validateBeforeSubmit()"
              ) {{_('Замовити')}}
              validation-provider(
                slim
                v-slot="{errors}"
                name="nonFieldErrors"
              )
                +b.P.error-text.ma--bottom_sm(v-if="errors[0]") {{ errors[0] }}
              bem-text(
                variant="center"
                tag="p"
                color="black"
                size="2xs"
                height="sm"
              ) {{_('Оформлюючи замовлення Ви погоджуєтеся з')}}
              span &nbsp;
              row(justify="center" space="xs")
                cell
                  bem-link(
                    variant="center"
                    block="link"
                    color="accent-1"
                    size="2xs"
                    height="sm"
                    :href="objectItem.privacy"
                    target="_blank"
                  ) {{_('політикою конфіденційності')}}
                cell
                  bem-text(
                    variant="center"
                    tag="span"
                    color="black"
                    size="2xs"
                    height="sm"
                  )  &nbsp; {{_('та')}} &nbsp;
                cell
                  bem-link(
                    variant="center"
                    block="link"
                    color="accent-1"
                    size="2xs"
                    height="sm"
                    :href="objectItem.rules"
                    target="_blank"
                  ) {{_('правилами сайту')}}
</template>

<script>
import { djangoToJsBoolean, valueFromSelect } from '@helpers/utils'
import FormMixin from '@mixins/form.mixin.js'
import {
  cartListResource,
  createOrderResource,
} from '@services/order.service.js'
import { getAmplitudeInfo } from '@helpers/amplitude'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'order-wrapper',
  mixins: [FormMixin],
  components: {
    'order-contact-form': () => import('./OrderContactForm'),
    'order-recipient-form': () => import('./OrderRecipientForm'),
    'order-auth-form': () => import('./OrderAuthForm'),
    'order-delivery': () => import('./OrderDelivery'),
    'order-payment': () => import('./OrderPayment'),
    'order-promocode': () => import('./OrderPromocode'),
    'order-item': () => import('./OrderItem'),
  },
  props: {
    objectItem: Object,
    // catalogLink: String,
    categoryListLink: String,
    linkOrder: String,
    indexPage: String,
  },
  data() {
    return {
      isAuth: djangoToJsBoolean(window.is_auth),
      formdata: {
        nameBuyer: '',
        surnameBuyer: '',
        emailBuyer: '',
        phoneBuyer: '',
        register: false,
        city: '',
        refCity: '',
        deliveryDetail: '',
        warehouse: '',
        warehouseNp: '',
        streetDeliver: '',
        houseDeliver: '',
        flatDeliver: '',
        methodPay: '',
        nameLegalEntity: '',
        codeEdrpoy: '',
        comment: '',
        promocode: '',
        countParts: 0,
      },
      citiesList: [],
      selectedTab: 'new',
      cartData: {},
      promocodeDiscount: 0,
      isPromo: null,
      isPromocode: false,
      computedFinalPrice: 0,
      isCartResponse: false,
      getParams: {},
      isApplyPromo: false,
      amplitudeSendLogs: {
        contact: false,
      },
    }
  },
  watch: {
    cart: {
      handler(nval) {
        if (0 === nval.quantity) window.location.replace(this.categoryListLink)
        else this.getCart()
      },
    },
    user: {
      handler(nval) {
        if (true === this.isAuth) {
          this.prefillUserData(nval)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.getCart()

    this.addGtagStartCheckoutEvent()

    this.sendAmplitudeEvent('to_place_an_order')

    if (true === this.isAuth) {
      this.userDataRequest()
    }

    this.parseParameters()
  },
  computed: {
    ...mapGetters('common', {
      cart: 'getCartData',
    }),
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
  methods: {
    ...mapActions('cabinet', [
      'userDataRequest',
    ]),
    async send(data) {
      data.getParams = this.getParams

      const copyData = JSON.parse(JSON.stringify(data))
      const formdata = await this.formatBeforeRequest(copyData)

      return createOrderResource.execute({}, formdata)
        .then(({ data: { item: { location, transactionId } } }) => {
          this.orderCreatedEvent(transactionId)

          window.location.replace(location)
        })
    },
    getItemsForDataLayer() {
      const { products } = this.cartData

      return products.map(el => {
        const {
          code, title, prices, color, category, count,
        } = el
        const { price2 } = prices
        return {
          item_id: code,
          item_name: title,
          item_category: category,
          price: price2,
          quantity: count,
          item_variant: color || '',
        }
      })
    },
    orderCreatedEvent(transactionId) {
      const items = this.getItemsForDataLayer()
      const { finalPrice } = this.cartData

      /* eslint-disable-next-line */
      gtag('event', 'purchase', {
        currency: 'UAH',
        value: finalPrice,
        shipping: 0,
        transaction_id: transactionId,
        items,
      })
    },
    formatBeforeRequest(data) {
      data.refCity = data.city.refCity
      data.refCityAddress = data.city.refCityAddress
      data.city = valueFromSelect(data.city, 'presentTitle')
      data.warehouse = valueFromSelect(data.warehouse, 'id')
      data.warehouseNp = valueFromSelect(data.warehouseNp, 'presentTitle')
      data.streetDeliver = valueFromSelect(data.streetDeliver, 'presentTitle')

      if ('pay_part' === data.methodPay && data.paymentBank) {
        data.methodPay = data.paymentBank

        delete data.paymentBank
      }

      return data
    },
    async getCart() {
      this.isLoad = true
      return cartListResource.execute().then(({ data: { item } }) => {
        if (item?.products && 0 !== item.products.length) {
          this.cartData = item
          this.isCartResponse = true
          this.isLoad = false
          this.computedFinalPrice = item.finalPrice
          this.isPromo = item.isPromo
        } else {
          window.location.replace(this.indexPage)
        }
        this.isApplyPromo = false
      })
    },
    handlePromocode(promocode) {
      this.promocodeDiscount = promocode
      this.computedFinalPrice = this.cartData.finalPrice - promocode
      this.isPromocode = true
      /* isApplyPromo is neccessary to understand can we
        update part payment or keep old max pay count
      */
      this.isApplyPromo = 0 !== promocode
    },
    prefillUserData(data) {
      this.formdata.nameBuyer = data.firstName
      this.formdata.surnameBuyer = data.lastName
      this.formdata.emailBuyer = data.email
      this.formdata.phoneBuyer = data.phone
      if (data.city && 'None' !== data.city && data.refCity && data.refCityAddress) {
        this.formdata.city = {
          presentTitle: data.city,
          refCity: data.refCity,
          refCityAddress: data.refCityAddress,
        }
      }
    },
    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')
      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },

    preselectPayment() {
      this.formdata.methodPay = 'pay_part'
    },

    contactDataChangeHandler(data) {
      this.formdata = data

      const fields = ['nameBuyer', 'surnameBuyer', 'phoneBuyer']

      const isFilled = fields.every(k => this.formdata[k])
      const { errors } = this.$refs.observer
      const hasNotErrors = fields.every(k => !errors[k].length)

      if (isFilled && hasNotErrors && !this.amplitudeSendLogs.contact) {
        this.amplitudeSendLogs.contact = true

        this.sendAmplitudeEvent('to_place_an_order_newbuyer_contact_filled')
      }
    },

    getItemsForAnalytics() {
      const { products } = this.cartData

      return products.map(el => {
        const {
          code, title, prices, color, category, count,
        } = el
        const { price2 } = prices
        return {
          id: code,
          name: title,
          category,
          price: price2,
          quantity: count,
          variant: color || '',
        }
      })
    },
    addGtagStartCheckoutEvent() {
      const { finalPrice } = this.cartData
      const items = this.getItemsForAnalytics()

      /* eslint-disable-next-line */
      gtag('event', 'begin_checkout', {
        items,
        currency: 'UAH',
        value: finalPrice,
      })
    },

    deliveryChangeEvent(option) {
      this.gtagDeliveryChange(option)

      this.sendAmplitudeEvent('to_place_an_order_newbuyer_delivery_selected')
    },

    paymentChangeEvent(option) {
      this.gtagPaymentChange(option)

      this.sendAmplitudeEvent('to_place_an_order_newbuyer_paymentmethod_selected')
    },

    gtagDeliveryChange(option) {
      /* eslint-disable-next-line */
      gtag('event', 'set_checkout_option', {
        checkout_step: 1,
        checkout_option: 'shipping method',
        value: option,
      })
    },

    gtagPaymentChange(option) {
      /* eslint-disable-next-line */
      gtag('event', 'set_checkout_option', {
        checkout_step: 1,
        checkout_option: 'payment method',
        value: option,
      })
    },

    selectTab(val) {
      this.selectedTab = val

      if ('regular' === this.selectedTab) {
        this.sendAmplitudeEvent('to_place_an_order_regular_customer_selected')
      }
    },

    sendAmplitudeEvent(event) {
      /* eslint-disable no-undef */
      if (!amplitude) return

      const info = getAmplitudeInfo()
      const { finalPrice } = this.cartData

      info.amount = finalPrice

      /* eslint-disable no-undef */
      amplitude.logEvent(event, info)
    },
  },
}
</script>
