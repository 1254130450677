<script>
import { deleteTrackResource } from '@services/common.service.js'

export default {
  name: 'delete-tracked-action',
  props: {
    productId: Number,
  },
  methods: {
    deleteProduct() {
      return deleteTrackResource.execute({}, { idProduct: this.productId })
        .then(() => { this.$emit('delete') })
    },
  },
  render() {
    return this.$scopedSlots.default({
      action: this.deleteProduct,
    })
  },
}
</script>
